import dayjs from "dayjs";
import React, { Fragment, useState, useEffect, useMemo } from "react";
import { UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Upload,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { connect } from "react-redux";
import {
  actionGetUserDetail,
  actionUpdateUser,
} from "../../store/actions/userAction";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL_UPLOAD } from "../config/web-config";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UpdateUser = (props) => {
  const {
    userUpdateLoader,
    userDetailLoader,
    userDetailData,
    actionGetUserDetail,
    actionUpdateUser,
  } = props;

  const [loading, setLoading] = useState(false);
  const [userPicUrl, setUserPicUrl] = useState();
  const { userId } = useParams();

  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    actionGetUserDetail(userId);
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      first_name: userDetailData.first_name,
      last_name: userDetailData.last_name,
      email: userDetailData.email,
      country_phone_code: userDetailData.country_phone_code,
      phone: userDetailData.phone,
      // password: userDetailData.password,
      roles: userDetailData.roles,
      date_of_birth: userDetailData.date_of_birth
        ? dayjs(userDetailData.date_of_birth)
        : undefined,
      profile_pic: userDetailData.profile_pic,
      gender: userDetailData.gender,
      nationality: userDetailData.nationality,
      country_of_residence: userDetailData.country_of_residence,
    });

    setUserPicUrl(userDetailData.profile_pic);
  }, [userDetailData]);

  useMemo(() => {}, [userDetailData]);

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUserPicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      profile_pic: userPicUrl
        ? userPicUrl.split("/").reverse()[0]
        : values.profile_pic.file.response.file.filename,
      date_of_birth: values.date_of_birth.format("YYYY-MM-DD"),
    };
    actionUpdateUser(request, Navigate, userId);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <Spin spinning={userDetailLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    Update <span className="text-line">User</span>
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Form
                    autoComplete="off"
                    requiredMark={true}
                    form={form}
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[32, 0]}>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter first name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for first Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="first name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="last_name"
                          label="Last Name"
                          normalize={(value) =>
                            value.replace(/[^a-zA-Z]/g, "").trim()
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please enter last name!",
                            },
                            {
                              validator: (_, value) =>
                                value.length <= 15
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter maximum 15 characters for last Name"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="last name"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Email"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter email!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Email"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          colon={false}
                          label="Mobile"
                          name="country_phone_code"
                          rules={[
                            {
                              required: true,
                              message: "Please select country code.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="+91"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "1",
                                label: "+91",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10} className="hidden-label">
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Mobile"
                          colon={false}
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please enter mobile!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Mobile"
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                      <span>Password</span>
                        <Form.Item            normalize={(value) => value.trim()}

                        colon={false}
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter password!",
                          },
                        ]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </Col> */}
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Roles"
                          colon={false}
                          name="roles"
                          rules={[
                            {
                              required: true,
                              message: "Please select Roles.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select Role"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "admin",
                                label: "admin",
                              },
                              {
                                value: "user",
                                label: "user",
                              },
                              {
                                value: "bot",
                                label: "bot",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Date Of Birth"
                          name="date_of_birth"
                          rules={[
                            {
                              required: true,
                              message: "Please enter date of birth!",
                            },
                          ]}
                        >
                          <DatePicker
                            onChange={onChange}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Gender"
                          normalize={(value) => value.trim()}
                          colon={false}
                          name="gender"
                          rules={[
                            {
                              required: true,
                              message: "Please select gender.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select gender"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "M",
                                label: "Male",
                              },
                              {
                                value: "F",
                                label: "Female",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Nationality"
                          colon={false}
                          name="nationality"
                          rules={[
                            {
                              required: true,
                              message: "Please select nationality.!",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select nationality"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "").toLowerCase()
                                )
                            }
                            options={[
                              {
                                value: "Indian",
                                label: "Indian",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          normalize={(value) => value.trim()}
                          label="Country of Residence"
                          colon={false}
                          name="country_of_residence"
                          rules={[
                            {
                              required: true,
                              message: "Please select country of residence.!",
                            },
                          ]}
                        >
                          <Input placeholder="country of residence" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Image*(200px * 200px)"
                          normalize={(value) => value.trim()}
                          name="profile_pic"
                          rules={[
                            {
                              required: userPicUrl ? false : true,
                              message: "Please input image!",
                            },
                          ]}
                        >
                          <ImgCrop rotate aspect={4 / 3} fillColor="white">
                            <Upload
                              name="file"
                              maxCount={1}
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem(
                                    "GrandealzBackendJwtToken"
                                  ),
                              }}
                              onChange={handleChangeUserPic}
                            >
                              {userPicUrl ? (
                                <img
                                  src={userPicUrl}
                                  alt="file"
                                  style={{
                                    width: "75%",
                                  }}
                                />
                              ) : (
                                <Button icon={<UploadOutlined />}>
                                  Select File
                                </Button>
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item normalize={(value) => value.trim()}>
                          <Button
                            loading={userUpdateLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userUpdateLoader: state.users.userUpdateLoader,
    userDetailLoader: state.users.userDetailLoader,
    userDetailData: state.users.userDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionUpdateUser,
})(UpdateUser);
