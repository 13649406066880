import * as actionTypes from "../action";

const initialState = {
  drawsLoader: false,
  drawsData: [],
  addDrawsLoader: false,
  addDrawsData: [],
  uploadDrawsPicLoader: false,
  uploadDrawsPicData: {},
  deleteDrawLoading: false,
  drawUpdateLoader: false,
  drawDetailLoader: false,
  drawDetailData: {},
  announceWinnerDrawDetailLoader: false,
  announceWinnerDrawDetailData: {},
  drawsWinnerDetailLoader: false,
  drawsCount: {},
};

const drawReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_DRAWS_COUNT:
      return { ...state, drawsCount: action.payload };

    case actionTypes.GET_DRAWS_LOADING:
      return { ...state, drawsLoader: action.payload };

    case actionTypes.GET_DRAWS_DATA:
      return { ...state, drawsData: action.payload };

    case actionTypes.ADD_DRAWS_LOADING:
      return { ...state, addDrawsLoader: action.payload };

    case actionTypes.ADD_DRAWS_DATA:
      return { ...state, addDrawsData: action.payload };

    case actionTypes.UPLOAD_DRAWS_PIC_LOADING:
      return { ...state, uploadDrawsPicLoader: action.payload };

    case actionTypes.UPLOAD_DRAWS_PIC_DATA:
      return { ...state, uploadDrawsPicData: action.payload };

    case actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_LOADING:
      return { ...state, announceWinnerDrawDetailLoader: action.payload };

    case actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_DATA:
      return {
        ...state,
        announceWinnerDrawDetailData: action.payload,
      };

    case actionTypes.DELETE_DRAW_LOADING:
      return { ...state, deleteDrawLoading: action.payload };

    case actionTypes.DELETE_DRAW_ID:
      return {
        ...state,
        drawsData: [
          ...state.drawsData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_DRAW_LOADING:
      return { ...state, drawUpdateLoader: action.payload };

    case actionTypes.GET_DRAW_DETAIL_LOADING:
      return { ...state, drawDetailLoader: action.payload };

    case actionTypes.GET_DRAW_DETAIL_DATA:
      return {
        ...state,
        drawDetailData: action.payload,
      };

    case actionTypes.DRAWS_ANNOUNCE_WINNER_LOADING:
      return { ...state, drawsWinnerDetailLoader: action.payload };

    case actionTypes.DRAWS_ANNOUNCE_WINNER_DATA:
      return {
        ...state,
        drawsData: state.drawsData.map((data) =>
          data._id === action.payload._id
            ? {
              ...data,
              winner: action.payload.winner,
            }
            : data
        ),
      };

    default:
      return state;
  }
};

export default drawReducer;
