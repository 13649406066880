import * as actionTypes from "../action";

const initialState = {
  usersLoader: false,
  usersData: [],
  addUserLoading: false,
  deleteUserLoading: false,
  addUserData: [],
  userUpdateLoader: false,
  userDetailLoader: false,
  userDetailData: {},
  usersCount: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_USERS_COUNT:
      return { ...state, usersCount: action.payload };

    case actionTypes.GET_USERS_LOADING:
      return { ...state, usersLoader: action.payload };

    case actionTypes.GET_USERS_DATA:
      return { ...state, usersData: action.payload };

    case actionTypes.ADD_USER_LOADING:
      return { ...state, addUserLoading: action.payload };

    case actionTypes.ADD_USER_DATA:
      return { ...state, addUserData: action.payload };

    case actionTypes.DELETE_USER_LOADING:
      return { ...state, deleteUserLoading: action.payload };

    case actionTypes.DELETE_USER_ID:
      return {
        ...state,
        usersData: [
          ...state.usersData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_USER_LOADING:
      return { ...state, userUpdateLoader: action.payload };

    case actionTypes.GET_USER_DETAIL_LOADING:
      return { ...state, userDetailLoader: action.payload };

    case actionTypes.GET_USER_DETAIL_DATA:
      return {
        ...state,
        userDetailData: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
