import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { List, Spin, Button, Divider, Row, Col } from "antd";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import ticket from "../../assets/img/comman/Grand deals coupon.jpg";
import {
  actionGetCampaignPrintTicket,
  actionGetCampaignDetail,
} from "../../store/actions/campaignAction";

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  code: {
    position: "absolute",
    fontSize: "5px",
    color: "white",
    top: "88px",
    left: "20%",
    fontWeight: "bold",
  },
  container: {
    width: "100%",
    flexDirection: "row",
  },
  mainView: {
    margin: 10,
    width: "50%",
    maxWidth: "50%",
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const RenderTickets = ({ t }) => (
  <View style={styles.mainView}>
    <Image
      preview={{
        mask: "",
      }}
      style={{ width: "calc(100%/2)", maxWidth: "calc(100%/2)" }}
      src={ticket}
    />
    <Text style={styles.code}>{t}</Text>
  </View>
);

const PrintTicketsPdf = (props) => {
  const {
    actionGetCampaignPrintTicket,
    actionGetCampaignDetail,
    campaignPrintTicketLoader,
    campaignPrintTicketData,
    campaignDetailData,
    productNotFound,
  } = props;

  const { campaginId } = useParams();
  const [chunkArray, setChunkArray] = useState([]);
  const skip = useRef(0);

  useEffect(() => {
    const sliceIntoChunks240 = (arr, chunkSize) => {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return setChunkArray(res);
    };
    sliceIntoChunks240(campaignPrintTicketData, 240);
  }, [campaignPrintTicketData]);

  useEffect(() => {
    actionGetCampaignPrintTicket(campaginId, skip.current);
    actionGetCampaignDetail(campaginId);
  }, []);

  const onLoadPrev = () => {
    const newSkip = (skip.current = parseInt(skip.current) - 1);
    actionGetCampaignPrintTicket(campaginId, newSkip);
  };

  const onLoadNext = () => {
    const newSkip = (skip.current = parseInt(skip.current) + 1);
    actionGetCampaignPrintTicket(campaginId, newSkip);
  };

  const loadMore = !campaignPrintTicketLoader ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      {campaignPrintTicketData && campaignPrintTicketData.length > 0 ? (
        <Row style={{ marginTop: "20px" }} gutter={[16,16]}>
          <Col span={12} align="right">
            <Button loading={campaignPrintTicketLoader} onClick={onLoadPrev}>
              load Previous
            </Button>
          </Col>
          <Col span={12} align="left">
            <Button loading={campaignPrintTicketLoader} onClick={onLoadNext}>
              load next
            </Button>
          </Col>
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col span={12} align="left">
              <Button loading={campaignPrintTicketLoader} onClick={onLoadPrev}>
                load Previous
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  ) : null;

  const chunk = (array, size) =>
    array.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(array.slice(i, i + size));
      return acc;
    }, []);
  // Create Document Component
  const MyDocument = (item) =>
    item && (
      <Document>
        <Page wrap size="A4" style={styles.page}>
          {chunk(item.data, 2).length > 0 &&
            chunk(item.data, 2).map((tArray, i) => (
              <View style={styles.container} key={i}>
                {tArray.length > 0 &&
                  tArray.map((tt, i) => <RenderTickets key={i} t={tt} />)}
                {tArray.length % 2 > 0 && <View style={styles.mainView}></View>}
              </View>
            ))}

          <Text
            style={styles.pageNumbers}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      </Document>
    );

  return (
    <Spin spinning={campaignPrintTicketLoader}>
      <List
        className="pdf-list"
        itemLayout="horizontal"
        loadMore={loadMore}
        dataSource={chunkArray}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <FilePdfOutlined
                  style={{ fontSize: "25px", color: "#e70736" }}
                />
              }
              title={
                parseInt(index) === 0
                  ? `${
                      campaignDetailData.campaign_name
                        ? campaignDetailData.campaign_name
                        : "Please wait..."
                    }`
                  : `${
                      campaignDetailData.campaign_name
                        ? campaignDetailData.campaign_name
                        : "please wait..."
                    }-${index}`
              }
              description="This pdf have 480 data and it have 60 pages"
            />
            <PDFDownloadLink
              document={<MyDocument data={item} />}
              fileName={
                parseInt(index) === 0
                  ? `${
                      campaignDetailData.campaign_name
                        ? campaignDetailData.campaign_name
                        : "please wait..."
                    }.pdf`
                  : `${
                      campaignDetailData.campaign_name
                        ? campaignDetailData.campaign_name
                        : "please wait..."
                    }-${index}.pdf`
              }
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    type="primary"
                    shape="round"
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>
                )
              }
            </PDFDownloadLink>
          </List.Item>
        )}
      />
    </Spin>
    // <PDFViewer>
    //   <MyDocument />
    // </PDFViewer>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignPrintTicketLoader: state.campaign.campaignPrintTicketLoader,
    campaignPrintTicketData: state.campaign.campaignPrintTicketData,
    campaignDetailData: state.campaign.campaignDetailData,
    productNotFound: state.campaign.productNotFound,
  };
};

export default connect(mapStateToProps, {
  actionGetCampaignPrintTicket,
  actionGetCampaignDetail,
})(PrintTicketsPdf);
