import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  message,
  Row,
  Typography,
  Spin,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionGetDrawDetail,
  actionUpdateDraw,
  actionUploadDrawsPic,
} from "../../store/actions/drawAction";
import { BASE_URL_UPLOAD } from "../config/web-config";
const { TextArea } = Input;
const { Option } = Select;

const UpdateDraw = (props) => {
  const {
    actionUpdateDraw,
    addDrawsLoader,
    drawUpdateLoader,
    drawDetailLoader,
    drawDetailData,
    actionGetDrawDetail,
    actionUploadDrawsPic,
  } = props;

  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [productImageUrl, setProductImageUrl] = useState();
  const [drawImageUrl, setDrawImageUrl] = useState();

  const { drawId } = useParams();

  useEffect(() => {
    actionGetDrawDetail(drawId);
  }, []);

  useMemo(() => {}, [drawDetailData]);

  useEffect(() => {
    form.setFieldsValue({
      draw_title: drawDetailData.draw_title,
      max_draw_date_description: drawDetailData.max_draw_date_description,
      draw_description: drawDetailData.draw_description,
      status: drawDetailData.status,
      product_title: drawDetailData.product_title,
      draw_sub_title: drawDetailData.draw_sub_title,
      product_description: drawDetailData.product_description,
      product_price: drawDetailData.product_price,
      product_image: drawDetailData.product_image,
      draw_image: drawDetailData.draw_image,
      draw_date: drawDetailData.draw_date
        ? dayjs(drawDetailData.draw_date)
        : null,
      max_draw_date: drawDetailData.max_draw_date
        ? dayjs(drawDetailData.max_draw_date)
        : null,
      coins_given: drawDetailData.coins_given,
      coins_redeem: drawDetailData.coins_redeem,
      total_no_of_tickets: drawDetailData.total_no_of_tickets,
    });
    setProductImageUrl(drawDetailData.product_image);
    setDrawImageUrl(drawDetailData.draw_image);
  }, [drawDetailData]);

  const onFinish = (values) => {
    const request = {
      ...values,
      product_image: productImageUrl
        ? productImageUrl.split("/").reverse()[0]
        : values.product_image.file.response.file.path,
      draw_image: drawImageUrl
        ? drawImageUrl.split("/").reverse()[0]
        : values.draw_image.file.response.file.filename,
      draw_date: values.draw_date
        ? dayjs(values.draw_date).format("YYYY-MM-DD")
        : null,
      max_draw_date: values.max_draw_date
        ? dayjs(values.max_draw_date).format("YYYY-MM-DD")
        : null,
      coins_given: parseInt(values.coins_given),
      coins_redeem: parseInt(values.coins_redeem),
      total_no_of_tickets: parseInt(values.total_no_of_tickets),
    };
    actionUpdateDraw(request, Navigate, drawId);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    console.log("File size:", file.size / 1024, "KB");
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isLt2M;
  };

  const handleChangeProductImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProductImageUrl(info.file.response.file.path);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChangeDrawImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);

        setDrawImageUrl(info.file.response.file.path);

        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Fragment>
      <Spin spinning={drawDetailLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row>
                <Col span={24}>
                  <Typography.Title level={4}>
                    Update <span className="text-line">Draw</span>
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Form
                    autoComplete="off"
                    requiredMark={true}
                    layout="vertical"
                    name="normal_login"
                    form={form}
                    size="large"
                    onFinish={onFinish}
                  >
                    <Row gutter={[48, 0]}>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="draw_title"
                          label="Draw Title"
                          rules={[
                            {
                              required: true,
                              message: "Please enter draw_title !",
                            },
                            {
                              validator: (_, value) =>
                                value.length >= 5
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(
                                        "Please enter minimum 5 characters for Draw Title"
                                      )
                                    ),
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Title"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Total No Of Tickets"
                          colon={false}
                          name="total_no_of_tickets"
                          rules={[
                            {
                              required: true,
                              message: "Please enter total no of tickets!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"number"}
                            placeholder="Total no of Tickets"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Product Title"
                          name="product_title"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Product title!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Product Title"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="draw_sub_title"
                          label="Draw Sub Title"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Draw sub title!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Draw Sub Title"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="product_description"
                          label="Product Description"
                          rules={[
                            {
                              required: true,
                              message: "Please enter product description!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value.length <= 160) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Please enter Maximum 160 characters for Product Description"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <TextArea
                            name="product_description"
                            rows={4}
                            placeholder="product description"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Draw Description"
                          name="draw_description"
                          rules={[
                            {
                              required: true,
                              message: "Please enter draw description!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value.length <= 160) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Please enter Maximum 160 characters for Draw Description"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <TextArea
                            name="draw_description"
                            rows={4}
                            placeholder="draw description"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Draw Date"
                          name="draw_date"
                          rules={[
                            {
                              required: false,
                              message: "Please enter draw date!",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Max Draw Date"
                          name="max_draw_date"
                          rules={[
                            {
                              required: false,
                              message: "Please enter max draw date!",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="product_price"
                          label="Product Price"
                          rules={[
                            {
                              required: true,
                              message: "Please enter product price!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="product price"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Max Draw Date Description"
                          name="max_draw_date_description"
                          rules={[
                            {
                              required: false,
                              message:
                                "Please enter max draw date description!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Max Draw Date Description"
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Coins Given"
                          name="coins_given"
                          rules={[
                            {
                              required: false,
                              message: "Please enter coins given!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Coins Given"
                          />
                        </Form.Item>
                      </Col> */}
                      {/* <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Coins Redeem"
                          name="coins_redeem"
                          rules={[
                            {
                              required: false,
                              message: "Please enter coins redeem!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Coins Redeem"
                          />
                        </Form.Item>
                      </Col> */}

                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="status"
                          label="Status"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Status!",
                            },
                          ]}
                        >
                          <Select placeholder="Select a Status" allowClear>
                            <Option value="Publish">Publish</Option>
                            <Option value="UpComming">UpComing</Option>
                            <Option value="Draft">Draft</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12} />
                      <Col span={12}>
                        <Form.Item
                          name="product_image"
                          label="Product Image* (1000px * 1000px)"
                          rules={[
                            {
                              required: productImageUrl ? false : true,
                              message: "Please input product image!",
                            },
                          ]}
                        >
                          <ImgCrop
                            rotate
                            aspect={1 / 1}
                            fillColor="transparent"
                          >
                            <Upload
                              beforeUpload={beforeUpload}
                              action={BASE_URL_UPLOAD}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem(
                                    "GrandealzBackendJwtToken"
                                  ),
                              }}
                              name="file"
                              maxCount={1}
                              showUploadList={false}
                              onChange={handleChangeProductImage}
                            >
                              {productImageUrl ? (
                                <img
                                  src={productImageUrl}
                                  alt="file"
                                  style={{
                                    width: 200,
                                  }}
                                />
                              ) : (
                                <Button icon={<UploadOutlined />}>
                                  Select File
                                </Button>
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="draw_image"
                          label="Draw Image* (1000px * 560px)"
                          rules={[
                            {
                              required: drawImageUrl ? false : true,
                              message: "Please input image!",
                            },
                          ]}
                        >
                          <ImgCrop rotate aspect={5 / 3} fillColor="white">
                            <Upload
                              beforeUpload={beforeUpload}
                              action={BASE_URL_UPLOAD}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem(
                                    "GrandealzBackendJwtToken"
                                  ),
                              }}
                              name="file"
                              maxCount={1}
                              showUploadList={false}
                              onChange={handleChangeDrawImage}
                            >
                              {drawImageUrl ? (
                                <img
                                  src={drawImageUrl}
                                  alt="file"
                                  style={{
                                    width: 200,
                                  }}
                                />
                              ) : (
                                <Button icon={<UploadOutlined />}>
                                  Select File
                                </Button>
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <Button
                            loading={drawUpdateLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    uploadDrawsPicLoader: state.draws.uploadDrawsPicLoader,
    uploadDrawsPicData: state.draws.uploadDrawsPicData,
    drawUpdateLoader: state.draws.drawUpdateLoader,
    drawDetailLoader: state.draws.drawDetailLoader,
    drawDetailData: state.draws.drawDetailData,
  };
};

export default connect(mapStateToProps, {
  actionUpdateDraw,
  actionGetDrawDetail,
  actionUploadDrawsPic,
})(UpdateDraw);
