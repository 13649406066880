import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionAddCoupan } from "../../store/actions/coupanAction";

const CreateCoupan = (props) => {
  const { Option } = Select;
  const { actionAddCoupan, addCoupansLoader, addCoupansData } = props;

  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onFinish = (values) => {
    const request = {
      ...values,
      quantity: parseInt(values.quantity),
      user_qty: parseInt(values.user_qty),
      discount: parseInt(values.discount),
      discount_up_to: parseInt(values.discount_up_to),
      min_cart_total: parseInt(values.min_cart_total),
      from: dayjs(values.from).format("YYYY-MM-DD"),
      to: dayjs(values.to).format("YYYY-MM-DD"),
    };

    actionAddCoupan(request, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:");
  };
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row>
              <Col span={24}>
                <Typography.Title level={4}>
                  Create <span className="text-line">Coupan</span>
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  layout="vertical"
                  name="normal_login"
                  form={form}
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[48, 0]}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label='Coupon Code'
                        name="coupon"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Coupan Code !",
                          },
                          {
                            validator: (_, value) =>
                              value.length >= 8
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter mininum 8 characters  Coupan Code"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Coupon Code"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Coupon Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Coupon Description!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Coupon Description"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Coupon Quantity"
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Coupon Quantity!",
                          },
                          {
                            validator: (_, value) =>
                              value >= 1
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Coupon Quantity should be greater than 0."
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Coupon Quantity"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        name="user_qty"
                        label='User Quantity'
                        rules={[
                          {
                            required: true,
                            message: "Please enter User Quantity!",
                          },
                          {
                            validator: (_, value) =>
                              value >= 1
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "User Qauntity should be greater than 0."
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="User Quantity"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        name="discount"
                        label="Discount"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Discount!",
                          },
                          {
                            validator: (_, value) =>
                              value >= 1
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Discount should be greater than 0."
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Discount"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Discount type"
                        name="discount_type"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Discount type!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Flat",
                              label: "Flat",
                            },
                            {
                              value: "Percentage",
                              label: "Percentage",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        name="discount_up_to"
                        label="Discount Up To"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Discount Up To!",
                          },
                          {
                            validator: (_, value) =>
                              value >= 1
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Discount Up To should be greater than 0."
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Discount Up To"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        name="min_cart_total"
                        label="Min Cart Total"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Min Cart Total!",
                          },
                          {
                            validator: (_, value) =>
                              value >= 1
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Min Cart total should be greater than 0."
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Min Cart Total"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label='Coupon Start Date'
                        name="from"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Coupon Start Date!",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label='Coupon Expire Date'
                        name="to"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Coupon Expire Date!",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label='Status'
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Status!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a Status"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={[
                            {
                              value: "Draft",
                              label: "Draft",
                            },
                            {
                              value: "Active",
                              label: "Active",
                            },
                            {
                              value: "Archived",
                              label: "Archived",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={4}>
                      <Form.Item>
                        <Button
                          loading={addCoupansLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addCoupansLoader: state.draws.addCoupansLoader,
    addCoupansData: state.draws.addCoupansData,
  };
};

export default connect(mapStateToProps, {
  actionAddCoupan,
})(CreateCoupan);
