import React, { useMemo } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import "./assets/scss/index.scss";
import MainLayout from "./components/comman/MainLayout";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/auth/Login";
import CreateBanner from "./components/Banner/CreateBanner";
import CreateDraw from "./components/Draw/CreateDraw";
import CreateUser from "./components/Users/CreateUser";
import { actionGetUserProfile } from "../src/store/actions/authAction";
import { connect } from "react-redux";
import setAuthToken from "./components/config/setAuthToken";
import Banner from "./components/Banner/Banner";
import Draw from "./components/Draw/Draw";
import User from "./components/Users/User";
import ResetPassword from "./components/auth/ResetPassword";
import UpdateBanner from "./components/Banner/UpdateBanner";
import UpdateDraw from "./components/Draw/UpdateDraw";
import UpdateUser from "./components/Users/UpdateUser";
import Coupan from "./components/Coupan/Coupan";
import CreateCoupan from "./components/Coupan/CreateCoupan";
import UpdateCoupan from "./components/Coupan/UpdateCoupan";
import Campaign from "./components/campaign/Campaign";
import CreateCampaign from "./components/campaign/CreateCampaign";
import UpdateCampaign from "./components/campaign/UpdateCampaign";
import ChangePassword from "./components/auth/ChangePassword";
import CampaignDetail from "./components/campaign/CampaignDetail";
import PrintTicketsPdf from "./components/campaign/PrintTicketsPdf";
import ForgotPassword from "./components/auth/ForgotPassword";
import EditProfile from "./components/auth/EditProfile";
import Orders from "./components/Orders/Orders";
import OrderDetail from "./components/Orders/OrderDetail";
import BannerDetail from "./components/Banner/BannerDetail";
import CoupanDetail from "./components/Coupan/CoupanDetail";
import UserDetail from "./components/Users/UserDetail";
import ViewCustomer from "./components/Draw/ViewCustomer";

const App = props => {
  const { actionGetUserProfile } = props;

  useMemo(() => {
    if (localStorage.GrandealzBackendJwtToken) {
      setAuthToken(localStorage.GrandealzBackendJwtToken);
      actionGetUserProfile();
    }
  }, [localStorage.GrandealzBackendJwtToken]);

  const RequireAuth = ({ children }) => {
    let location = useLocation();


    if (!localStorage.GrandealzBackendJwtToken) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return React.createElement(Navigate, {
        to: "/login",
        state: { from: location },
        replace: true,
      });
    }

    return children;
  };

  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/Login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/change-password"
            element={
              <RequireAuth>
                <ChangePassword />
              </RequireAuth>
            }
          />
          <Route
            path="//edit-profile"
            element={
              <RequireAuth>
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/create-banner"
            element={
              <RequireAuth>
                <CreateBanner />
              </RequireAuth>
            }
          />
          <Route
            path="/update-banner/:bannerId"
            element={
              <RequireAuth>
                <UpdateBanner />
              </RequireAuth>
            }
          />
          <Route
            path="/banner"
            element={
              <RequireAuth>
                <Banner />
              </RequireAuth>
            }
          />
          <Route
            path="/banner-detail/:bannerId"
            element={
              <RequireAuth>
                <BannerDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/draws"
            element={
              <RequireAuth>
                <Draw />
              </RequireAuth>
            }
          />
          <Route
            path="/create-draw"
            element={
              <RequireAuth>
                <CreateDraw />
              </RequireAuth>
            }
          />
          <Route
            path="/update-draw/:drawId"
            element={
              <RequireAuth>
                <UpdateDraw />
              </RequireAuth>
            }
          />
          <Route
            path="/view-customer"
            element={
              <RequireAuth>
                <ViewCustomer />
              </RequireAuth>
            }
          />
          <Route
            path="/users"
            element={
              <RequireAuth>
                <User />
              </RequireAuth>
            }
          />
          <Route
            path="/view-user/:userId"
            element={
              <RequireAuth>
                <UserDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/print-ticket/:campaginId"
            element={
              <RequireAuth>
                <PrintTicketsPdf />
              </RequireAuth>
            }
          />
          <Route
            path="/create-user"
            element={
              <RequireAuth>
                <CreateUser />
              </RequireAuth>
            }
          />
          <Route
            path="/update-user/:userId"
            element={
              <RequireAuth>
                <UpdateUser />
              </RequireAuth>
            }
          />
          <Route
            path="/coupans"
            element={
              <RequireAuth>
                <Coupan />
              </RequireAuth>
            }
          />
          <Route
            path="/coupan-detail/:coupanId"
            element={
              <RequireAuth>
                <CoupanDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/create-coupan"
            element={
              <RequireAuth>
                <CreateCoupan />
              </RequireAuth>
            }
          />
          <Route
            path="/update-coupan/:coupanId"
            element={
              <RequireAuth>
                <UpdateCoupan />
              </RequireAuth>
            }
          />
          <Route
            path="/campaign"
            element={
              <RequireAuth>
                <Campaign />
              </RequireAuth>
            }
          />
          <Route
            path="/create-campaign"
            element={
              <RequireAuth>
                <CreateCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/update-campaign/:campaignId"
            element={
              <RequireAuth>
                <UpdateCampaign />
              </RequireAuth>
            }
          />
          <Route
            path="/campaign-info/:campaignId"
            element={
              <RequireAuth>
                <CampaignDetail />
              </RequireAuth>
            }
          />
          <Route
            path="/orders"
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          />

          <Route
            path="/order-detail/:orderId"
            element={
              <RequireAuth>
                <OrderDetail />
              </RequireAuth>
            }
          />

          {/* <Route path="/coupons" element={<Coupan />} /> */}


          <Route path="*" element={<div>Not Found</div>} />
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {};
};
export default connect(mapStateToProps, { actionGetUserProfile })(App);
