import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Select,
  Button,
  TreeSelect,
} from "antd";
import { Bar, Column, Pie } from "@ant-design/plots";
import { connect } from "react-redux";
import { actionGetUsersAll } from "../../store/actions/userAction";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { actionSendNotification } from "../../store/actions/notificationAction";
import { actionGetDashboardData } from "../../store/actions/orderAction";
const { Option } = Select;
const { TextArea } = Input;

const Dashboard = (props) => {
  const {
    actionGetUsersAll,
    usersLoader,
    actionGetDashboardData,
    dashboardData,
    dashboardLoader,
    actionSendNotification,
    usersCount,
    notificationLoader,
    notificationData,
    usersData,
  } = props;

  const [selectedValues, setSelectedValues] = useState([]);
  const [validationMessage, setValidationMessage] = useState("");

  console.log(usersData.length);

  console.log(usersCount);
  useEffect(() => {
    actionGetUsersAll(0, 500);
    actionGetDashboardData();
  }, []);

  const x = usersData
    .filter((user) => user.roles === "admin" || user.roles === "user")
    .map((user) => ({
      title: user.first_name + " " + user.last_name,
      value: user._id,
    }));
  console.log(!selectedValues || selectedValues.length === 0);

  const onFinish = (values) => {
    if (selectedValues.length === 0 && !values.title && !values.description) {
      setValidationMessage("Please Select User and enter title and message");
    } else if (selectedValues.length === 0) {
      setValidationMessage("Please Select User");
    } else {
      setValidationMessage("");
      console.log("object");
      actionSendNotification({ ...values, users: selectedValues });
      form.resetFields();
      setSelectedValues([]);
    }
  };

  const onFinishFailed = (error) => {
    console.log("Error", error);
  };
  const allIds = usersData.map((e) => e._id);

  const [form] = Form.useForm();

  const data = [];

  dashboardData.topFiveDraws &&
    dashboardData.topFiveDraws.length > 0 &&
    dashboardData.topFiveDraws.map((draw, index) => {
      data.push({
        key: index,
        Draw: `${draw.draw}`,
        value: draw.value,
      });
    });

  const dataPie = [];
  dashboardData.draw_analysis &&
    dashboardData.draw_analysis.length &&
    dashboardData.draw_analysis.map((d, i) => {
      dataPie.push({
        key: i,
        type: d.type,
        value: d.value,
      });
    });

  const dataMonth = [];

  dashboardData.monthly_business_growth &&
    dashboardData.monthly_business_growth.length > 0 &&
    dashboardData.monthly_business_growth.map((d, i) => {
      dataMonth.push({
        name: d.name,
        month: d.month,
        value: d.value,
      });
    });

  const config = {
    data: data,
    //color: "#E70736",
    isGroup: true,
    xField: "Draw",
    yField: "value",
    seriesField: "Draw",
    label: {
      position: "top-left",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    legend: {
      position: "top-left",
    },
    layout: [
      {
        type: "interval-adjust-position",
      },
      {
        type: "interval-hide-overlap",
      },
      {
        type: "adjust-color",
      },
    ],
    minColumnWidth: 100,
  };

  console.log(dashboardData.topFiveDraws);

  const configMonth = {
    data: dataMonth,
    //color: "#E70736",
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "month",
    legend: {
      position: "middle",
    },
    layout: [
      {
        type: "interval-adjust-position",
      },
      {
        type: "interval-hide-overlap",
      },
      {
        type: "adjust-color",
      },
    ],
    minColumnWidth: 70,
  };

  const configPie = {
    appendPadding: 10,
    data: dataPie,
    //color: ["#FF0000", "#FFCB4D", "#010F89"],
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };
  return (
    <Fragment>
      <Row gutter={[16, 16]} className="top-card">
        <Col span={6}>
          <Card title="Total Draws" align="middle" bordered={false}>
            {dashboardData &&
            dashboardData.dashboard_count &&
            dashboardData.dashboard_count.total_draws
              ? dashboardData.dashboard_count.total_draws
              : ""}
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Today Sold Tickets" align="middle" bordered={false}>
            {dashboardData &&
            dashboardData.dashboard_count &&
            dashboardData.dashboard_count.today_sold_tickets
              ? dashboardData.dashboard_count.today_sold_tickets
              : ""}
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Total Orders" align="middle" bordered={false}>
            {dashboardData &&
            dashboardData.dashboard_count &&
            dashboardData.dashboard_count.total_orders
              ? dashboardData.dashboard_count.total_orders
              : ""}
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Active users" align="middle" bordered={false}>
            {dashboardData &&
            dashboardData.dashboard_count &&
            dashboardData.dashboard_count.active_users
              ? dashboardData.dashboard_count.active_users
              : ""}
          </Card>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]} className="center-card">
            <Col span={12}>
              <Card className="for-height">
                <Form
                  autoComplete="off"
                  requiredMark={"optional"}
                  name="normal_login"
                  form={form}
                  size="large"
                  onFinishFailed={onFinishFailed}
                  onFinish={onFinish}
                >
                  <Typography.Title level={4}>
                    Send <span className="text-line">Notification</span>
                  </Typography.Title>
                  <div style={{ marginTop: "35px" }} />
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        colon={false}
                        name="title"
                        style={{ marginTop: "5px" }}
                        rules={[
                          {
                            required: true,
                            message: "Please enter title!",
                          },
                        ]}
                      >
                        <Input
                          name="title"
                          autoFocus="none"
                          type={"text"}
                          placeholder="Title"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        style={{ marginTop: "5px" }}
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter message!",
                          },
                        ]}
                      >
                        <TextArea
                          name="description"
                          rows={4}
                          placeholder="enter your message"
                        />

                        {/* <h2>{error ? "plz Select User" : ''}</h2> */}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <TreeSelect
                        filterTreeNode={(inputValue, treeNode) =>
                          typeof treeNode.title === "string" &&
                          treeNode.title
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                        showSearch
                        allowClear={true}
                        name="users"
                        placeholder="Select a User"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        style={{ width: "100%" }}
                        dropdownStyle={{ maxHeight: "300px" }}
                        onChange={(ids) => {
                          setSelectedValues(ids);
                          if (ids.length === 0) {
                            setValidationMessage("Please select a user");
                          } else {
                            setValidationMessage("");
                          }
                        }}
                        value={selectedValues}
                        maxTagCount={2}
                        treeData={[
                          {
                            title: (
                              <React.Fragment>
                                {selectedValues.length === x.length && (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      color: "#ccc",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    Select all
                                  </span>
                                )}
                                {selectedValues.length < x.length && (
                                  <span
                                    onClick={() => setSelectedValues(allIds)}
                                    style={{
                                      display: "inline-block",
                                      color: "#286FBE",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Select all
                                  </span>
                                )}
                                &nbsp;&nbsp;&nbsp;
                                {selectedValues.length === 0 && (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      color: "#ccc",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    Deselect all
                                  </span>
                                )}
                                {selectedValues.length > 0 && (
                                  <span
                                    onClick={() => setSelectedValues([])}
                                    style={{
                                      display: "inline-block",
                                      color: "#286FBE",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Deselect all
                                  </span>
                                )}
                              </React.Fragment>
                            ),
                            value: "TEST",
                            disableCheckbox: true,
                            disabled: true,
                          },
                          ...x,
                        ]}
                      />
                      <p style={{ color: "red", margin: 0 }}>
                        {validationMessage ? validationMessage : ""}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={4}>
                      <Form.Item>
                        <Button
                          loading={notificationLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "10px" }}
                        >
                          Send
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Draws" bordered={false}>
                <Pie {...configPie} />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Top 5 Draws" bordered={false}>
                <Column {...config} />
              </Card>
            </Col>
            <Col span={24}>
              <Card title="Monthly business Growth" bordered={false}>
                <Column {...configMonth} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    usersLoader: state.users.usersLoader,
    usersCount: state.users.usersCount,
    usersData: state.users.usersData,
    notificationLoader: state.notification.notificationLoader,
    notificationData: state.notification.notificationData,
    dashboardLoader: state.orders.dashboardLoader,
    dashboardData: state.orders.dashboardData,
  };
};
export default connect(mapStateToProps, {
  actionGetUsersAll,
  actionGetDashboardData,
  actionSendNotification,
})(Dashboard);
