import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message, Slider } from "antd";

export const actionGetCoupans = (skip, limit) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_ALL_COUPAN_LOADING, payload: true });
  try {
    let result = await axios.get(
      `${BASE_URL}/coupon?limit=${limit}&skip=${skip}`
    );
    dispatch({ type: actionTypes.GET_ALL_COUPAN_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_ALL_COUPAN_DATA,
        payload: result.data.data,
      });
      dispatch({
        type: actionTypes.TOTAL_COUPAN_COUNT,
        payload: result.data.total_records,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_ALL_COUPAN_DATA,
        payload: [],
      });
      dispatch({
        type: actionTypes.TOTAL_COUPAN_COUNT,
        payload: 0,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_ALL_COUPAN_LOADING, payload: false });
  }
};

export const actionDeleteCoupan = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_COUPAN_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/coupon/${id}`);
    dispatch({
      type: actionTypes.DELETE_COUPAN_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_COUPAN_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_COUPAN_LOADING,
      payload: false,
    });
  }
};

export const actionAddCoupan =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_COUPAN_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/coupon`, userData);
      dispatch({ type: actionTypes.ADD_COUPAN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_COUPAN_DATA,
          payload: result.data.data,
        });
        message.success("Coupon Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/coupans`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_COUPAN_LOADING, payload: false });
    }
  };

export const actionUpdateCoupan =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_COUPAN_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/coupon/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_COUPAN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/coupans`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_COUPAN_LOADING, payload: false });
    }
  };

export const actionGetCoupanDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_COUPAN_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/coupon/${id}`);
    dispatch({ type: actionTypes.GET_COUPAN_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_COUPAN_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_COUPAN_DETAIL_LOADING, payload: false });
  }
};
