import * as actionTypes from "../action";

const initialState = {
  ordersLoader: false,
  ordersData: [],
  orderDetailLoader: false,
  orderDetailData: {},
  ordersCount: {},
  changeOrderStatusLoader: false,
  dashboardLoader: false,
  dashboardData: {},
};

const drawReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_ORDERS_COUNT:
      return { ...state, ordersCount: action.payload };

    case actionTypes.GET_ORDERS_LOADING:
      return { ...state, ordersLoader: action.payload };

    case actionTypes.GET_ORDERS_DATA:
      return { ...state, ordersData: action.payload };

    case actionTypes.UPDATE_ORDER_STATUS_LOADING:
      return { ...state, changeOrderStatusLoader: action.payload };

    case actionTypes.UPDATE_ORDER_STATUS:
      return {
        ...state,
        ordersData: state.ordersData.map((data) =>
          data._id === action.payload.id
            ? {
                ...data,
                order_status: action.payload.order_status,
              }
            : data
        ),
      };

    case actionTypes.GET_ORDER_DETAIL_LOADING:
      return { ...state, orderDetailLoader: action.payload };

    case actionTypes.GET_ORDER_DETAIL_DATA:
      return {
        ...state,
        orderDetailData: action.payload,
      };

    case actionTypes.GET_DASHBOARD_LOADING:
      return { ...state, dashboardLoader: action.payload };

    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    default:
      return state;
  }
};

export default drawReducer;
