import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetOrders =
  (skip, limit, status, start_date, end_date) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ORDERS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/orders/get-all?limit=${limit}&skip=${skip}${
          status ? "&status=" + status : ""
        }${start_date ? "&start_date=" + start_date : ""}${
          end_date ? "&end_date=" + end_date : ""
        }`
      );
      dispatch({ type: actionTypes.GET_ORDERS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ORDERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_ORDERS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_ORDERS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_ORDERS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_ORDERS_LOADING, payload: false });
    }
  };

export const actionGetOrderDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_ORDER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/orders/${id}`);
    dispatch({ type: actionTypes.GET_ORDER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_ORDER_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_ORDER_DETAIL_LOADING, payload: false });
  }
};

// export const actionGetCampaignDetail = (id) => async (dispatch, getState) => {
//   dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: true });
//   try {
//     let result = await axios.get(`${BASE_URL}/campaign/${id}`);
//     dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: false });
//     if (parseInt(result.data.status) === 200) {
//       dispatch({
//         type: actionTypes.GET_CAMPAIGN_DETAIL_DATA,
//         payload: result.data.data,
//       });
//     } else message.error(result.data.message, 5);
//   } catch (error) {
//     dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: false });
//   }
// };

export const actionUpdateOrderStatus =
  (userData, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_ORDER_STATUS_LOADING, payload: true });
    try {
      let result = await axios.put(
        `${BASE_URL}/orders/change-order-status/${id}`,
        userData
      );
      dispatch({
        type: actionTypes.UPDATE_ORDER_STATUS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPDATE_ORDER_STATUS,
          payload: { id, order_status: userData.order_status },
        });
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.UPDATE_ORDER_STATUS_LOADING,
        payload: false,
      });
    }
  };

// dashboard data

export const actionGetDashboardData = () => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/orders/dashboard`);
    dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_DASHBOARD_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_DASHBOARD_LOADING, payload: false });
  }
};
