import {
  CheckCircleOutlined,
  EditFilled,
  EllipsisOutlined,
  CalendarOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Image,
  Row,
  Modal,
  Select,
  Table,
  Typography,
  Tooltip,
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cancel from "../../assets/img/icon/cancel.svg";
import eye from "../../assets/img/icon/eye.svg";
import {
  actionGetOrders,
  actionUpdateOrderStatus,
} from "../../store/actions/orderAction";
import dayjs from "dayjs";
const { Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const Orders = (props) => {
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [orderId, setOrderId] = useState("");

  const {
    actionGetOrders,
    ordersLoader,
    changeOrderStatusLoader,
    drawsLoader,
    actionDeleteDraw,
    actionUpdateOrderStatus,
    ordersData,
    drawsData,
    ordersCount,
  } = props;

  const [list, setList] = useState();
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dataList = [];

  useEffect(() => {
    actionGetOrders(0, 10);
    setList(dataList);
  }, []);

  useMemo(() => {
    setList(dataList);
  }, [ordersData]);

  //const NewOrdersCount = newOrderData && newOrderData.draw.filter(data => data.)
  console.log(ordersCount, "ordersCount");

  ordersData &&
    ordersData.map((e, index) => {
      ordersData.length > 0 &&
        dataList.push({
          key: index,
          _id: e._id,
          is_donate: e.is_donate,
          order_id: <Text strong>{e._id}</Text>,
          product_title: (
            <Tooltip
              color="#001529"
              placement="rightTop"
              title={
                e.draws &&
                e.draws.map((e, index) => (
                  <>
                    {e.draw.product_title}
                    <br />
                  </>
                ))
              }
            >
              <Text key={index} strong>
                {e.draws[0].draw.product_title}
              </Text>
            </Tooltip>
          ),
          qty: (
            <>
              <Text key={index} strong>
                {e.draws
                  .map((data) => data.qty)
                  .reduce((acc, qty) => acc + parseInt(qty), 0)}
              </Text>
            </>
          ),
          amount: <Text strong>{e.sub_total}</Text>,
          username: (
            <Text strong> {e.user.first_name + " " + e.user.last_name}</Text>
          ),
          tax: <Text strong>{e.tax}</Text>,
          product_price: (
            <Text key={index} strong>
              {e.total}
            </Text>
          ),
          order_date: (
            <Text strong type="danger">
              {e.createdAt ? (
                <>
                  <CalendarOutlined /> {dayjs(e.createdAt).format("YYYY-MM-DD")}
                </>
              ) : (
                "--"
              )}
            </Text>
          ),
          order_status: (
            <Text
              strong
              type={`${
                e.order_status === "Pending" ||
                e.order_status === "On Hold" ||
                e.order_status === "Pending Payment" ||
                e.order_status === "Payment Failed"
                  ? "danger"
                  : "success"
              }`}
            >
              <CheckCircleOutlined /> {e.is_donate ? "Donated" : e.order_status}
            </Text>
          ),
        });
    });

  const openModal = (id) => {
    setModalOpen(true);
    setOrderId(id);
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      width: "200px",
    },
    {
      title: "Product Name",
      dataIndex: "product_title",
      key: "product_title",
      width: "250px",
    },

    {
      title: "Username",
      dataIndex: "username",
      // width: "200px",
      key: "draw_title",
    },

    {
      title: "Quantity",
      dataIndex: "qty",
      width: "100px",

      key: "qty",
      sorter: {
        compare: (a, b) => a.qty - b.qty,
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      // width: "120px",
    },

    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      // width: "120px",
    },
    {
      title: "Total",
      dataIndex: "product_price",
      key: "product_price",
      // width: "120px",
    },

    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      key: "order_status",
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (item) => (
        <Dropdown
          placement="bottom"
          menu={{
            items: [
              {
                label: (
                  <span>
                    <Link
                      to={`/order-detail/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <Image
                        src={eye}
                        alt="eye"
                        preview={false}
                        style={{
                          color: "#FF3E66",
                          width: "19px",
                          marginRight: 10,
                        }}
                      />
                      View Order
                    </Link>
                  </span>
                ),
              },
              {
                label: "Cancel Order",
                key: "cancel_order",
                icon: (
                  // <Cance
                  <Image
                    src={cancel}
                    alt="cancelImage"
                    preview={false}
                    style={{ color: "#00960F", width: "16px" }}
                  />
                ),
              },
              {
                label: "Add/Update Note",
                key: "add_update_note",
                icon: (
                  <EditFilled
                    style={{
                      color: "#FF3E66",
                      fontSize: "18px",
                      marginRight: 10,
                    }}
                  />
                ),
              },
              {
                disabled: item.is_donate,
                label: (
                  <span
                    onClick={() => (item.is_donate ? "" : openModal(item._id))}
                  >
                    <SyncOutlined
                      style={{
                        color: "#FF3E66",
                        fontSize: "18px",
                        marginRight: 10,
                      }}
                    />
                    Change Order Status
                  </span>
                ),
              },
            ],
          }}
        >
          <a>
            <EllipsisOutlined style={{ fontSize: "20px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const onFinish = async (values) => {
    const result = actionUpdateOrderStatus(values, orderId);
    result && setModalOpen(false);
    console.log(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  const onClickPaginationNumber = (e) => {
    actionGetOrders(
      e.current - 1,
      e.pageSize,
      status ? status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };

  const onFinishSearch = (values) => {
    setStatus(values.status ? values.status : "");
    actionGetOrders(
      0,
      10,
      values.status ? values.status : "",
      startDate ? startDate : "",
      endDate ? endDate : ""
    );
  };
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              minHeight: "80vh",
            }}
          >
            <Row
              gutter={[16, 16]}
              className="pb-15"
              align="middle"
              style={{ paddingLeft: 5, paddingRight: 5 }}
            >
              <Col span={9}>
                <Typography.Title level={4}>
                  Orders <span className="text-line"></span>
                </Typography.Title>
                <Typography.Title level={5}>
                  Total Records: {ordersCount ? ordersCount.toString() : ""}
                </Typography.Title>
              </Col>
              <Col span={15} align="right">
                <Form
                  name="basic"
                  layout="inline"
                  style={{ justifyContent: "end" }}
                  onFinish={onFinishSearch}
                  autoComplete="off"
                >
                  <Form.Item name="date">
                    <RangePicker onChange={onRangeChange} format="YYYY-MM-DD" />
                  </Form.Item>
                  <Form.Item name="status">
                    <Select
                      showSearch
                      placeholder="Select Status"
                      optionFilterProp="children"
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Confirmed",
                          label: "Confirmed",
                        },
                        {
                          value: "Shiped",
                          label: "Shiped",
                        },
                        {
                          value: "Out For Delivery",
                          label: "Out For Delivery",
                        },
                        {
                          value: "Delivered",
                          label: "Delivered",
                        },
                        {
                          value: "Cancelled",
                          label: "Cancelled",
                        },
                        {
                          value: "Pending",
                          label: "Pending",
                        },
                        {
                          value: "On Hold",
                          label: "On Hold",
                        },
                        {
                          value: "Refunded",
                          label: "Refunded",
                        },
                        {
                          value: "Pending Payment",
                          label: "Pending Payment",
                        },
                        {
                          value: "Payment Failed",
                          label: "Payment Failed",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  sticky={true}
                  size="small"
                  pagination={{ total: ordersCount, hideOnSinglePage: true }}
                  columns={columns}
                  dataSource={list}
                  loading={ordersLoader}
                  onChange={(e) => onClickPaginationNumber(e)}
                  scroll={{
                    x: 1500,
                    y: window.innerWidth < 1500 ? "40vh" : "60vh",
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Modal
        title={<div style={{ textAlign: "center" }}>Change Order Status</div>}
        className="modal-Winner block"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => {
          setModalOpen(false);
          form.resetFields();
        }}
        footer={false}
      >
        <Row>
          <Col span={24}>
            <Form
              autoComplete="off"
              requiredMark={true}
              form={form}
              layout="vertical"
              size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                normalize={(value) => value.trim()}
                label="Order Status"
                colon={false}
                name="order_status"
                rules={[
                  {
                    required: true,
                    message: "Please select Order Status.!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Order Status"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Confirmed",
                      label: "Confirmed",
                    },
                    {
                      value: "Shiped",
                      label: "Shiped",
                    },
                    {
                      value: "Out For Delivery",
                      label: "Out For Delivery",
                    },
                    {
                      value: "Delivered",
                      label: "Delivered",
                    },
                    {
                      value: "Cancelled",
                      label: "Cancelled",
                    },
                    {
                      value: "Pending",
                      label: "Pending",
                    },
                    {
                      value: "On Hold",
                      label: "On Hold",
                    },
                    {
                      value: "Refunded",
                      label: "Refunded",
                    },
                    {
                      value: "Pending Payment",
                      label: "Pending Payment",
                    },
                    {
                      value: "Payment Failed",
                      label: "Payment Failed",
                    },
                  ]}
                />
              </Form.Item>
              <Col span={24}>
                <Form.Item normalize={(value) => value.trim()}>
                  <Button
                    loading={changeOrderStatusLoader}
                    align="center"
                    htmlType="submit"
                    className="primary"
                    size="large"
                    style={{ width: 200, marginTop: "30px" }}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    drawsLoader: state.draws.drawsLoader,
    drawsData: state.draws.drawsData,
    deleteDrawLoading: state.draws.deleteDrawLoading,
    ordersData: state.orders.ordersData,
    ordersLoader: state.orders.ordersLoader,
    ordersCount: state.orders.ordersCount,
    changeOrderStatusLoader: state.orders.changeOrderStatusLoader,
  };
};

export default connect(mapStateToProps, {
  actionGetOrders,
  actionUpdateOrderStatus,
})(Orders);
