import { combineReducers } from "redux";
import authReducer from "./authReducer";
import bannerReducer from "./bannerReducer";
import drawReducer from "./drawReducer";
import userReducer from "./userReducer";
import coupanReducer from "./coupanReducer";
import campaignReducer from "./campaignReducer";
import orderReducer from "./orderReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  banner: bannerReducer,
  draws: drawReducer,
  users: userReducer,
  coupan: coupanReducer,
  campaign: campaignReducer,
  orders: orderReducer,
  notification: notificationReducer,
});

export default rootReducer;
