import {
  Card,
  Col,
  Row,
  Spin,
  Typography,
  Descriptions,
  Image,
  Popconfirm,
  Button,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  actionDeleteBanner,
  actionGetBannerDetail,
} from "../../store/actions/bannerAction";
const { Text, Paragraph } = Typography;

const BannerDetail = (props) => {
  const {
    actionGetBannerDetail,
    bannerDetailLoader,
    bannerDetailData,
    deleteBannerLoading,
    actionDeleteBanner,
  } = props;
  const Navigate = useNavigate();
  useEffect(() => {
    actionGetBannerDetail(bannerId);
  }, []);

  const { bannerId } = useParams();

  const confirm = (id) => {
    actionDeleteBanner(id);
    Navigate && setTimeout(() => Navigate(`/banner`), 1000);

    console.log("deleted");
  };
  return (
    <Fragment>
      <Spin spinning={bannerDetailLoader}>
        <Row gutter={[0, 24]} className="fragment">
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                minHeight: "80vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    View Banner <span className="text-line"></span>
                  </Typography.Title>
                </Col>
                <Descriptions
                  className="detail-page"
                  style={{ width: "100%" }}
                  column={1}
                  title=""
                  layout="horizontal"
                  bordered
                >
                  <Descriptions.Item label="Title">
                    {bannerDetailData ? bannerDetailData.title : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Button Text">
                    {bannerDetailData ? bannerDetailData.btn_text : ""}
                  </Descriptions.Item>

                  <Descriptions.Item label="Short Description">
                    {bannerDetailData ? bannerDetailData.description : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Image">
                    <Image
                      preview={{ mask: "" }}
                      style={{ width: 200 }}
                      src={bannerDetailData.image}
                      alt=""
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Background Image	">
                    <Image
                      preview={{ mask: "" }}
                      style={{ width: 300 }}
                      src={bannerDetailData.background_image}
                      alt=""
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <br />
              <Link to={`/update-banner/${bannerDetailData._id}`}>
                <Button
                  htmlType="button"
                  size="large"
                  style={{ marginRight: 10 }}
                >
                  Update
                </Button>
              </Link>
              <Popconfirm
                title="Are you sure to delete this Banner?"
                onConfirm={() => confirm(bannerDetailData._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  loading={deleteBannerLoading}
                  htmlType="button"
                  size="large"
                >
                  Delete
                </Button>
              </Popconfirm>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerDetailLoader: state.banner.bannerDetailLoader,
    bannerDetailData: state.banner.bannerDetailData,
    deleteBannerLoading: state.banner.deleteBannerLoading,
  };
};

export default connect(mapStateToProps, {
  actionGetBannerDetail,
  actionDeleteBanner,
})(BannerDetail);
