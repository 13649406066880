import * as actionTypes from "../action";

const initialState = {
  coupansLoader: false,
  deleteCoupanLoading: false,
  coupansData: [],
  addCoupansLoader: false,
  addCoupansData: [],
  coupanUpdateLoader: false,
  coupanDetailLoader: false,
  coupanDetailData: {},
  coupansCount: {},
};

const coupanReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOTAL_COUPAN_COUNT:
      return { ...state, coupansCount: action.payload };

    case actionTypes.GET_ALL_COUPAN_LOADING:
      return { ...state, coupansLoader: action.payload };

    case actionTypes.GET_ALL_COUPAN_DATA:
      return { ...state, coupansData: action.payload };

    case actionTypes.ADD_COUPAN_LOADING:
      return { ...state, addCoupansLoader: action.payload };

    case actionTypes.ADD_COUPAN_DATA:
      return { ...state, addCoupansData: action.payload };

    case actionTypes.DELETE_COUPAN_LOADING:
      return { ...state, deleteCoupanLoading: action.payload };

    case actionTypes.DELETE_COUPAN_ID:
      return {
        ...state,
        coupansData: [
          ...state.coupansData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_COUPAN_LOADING:
      return { ...state, coupanUpdateLoader: action.payload };

    case actionTypes.GET_COUPAN_DETAIL_LOADING:
      return { ...state, coupanDetailLoader: action.payload };

    case actionTypes.GET_COUPAN_DETAIL_DATA:
      return {
        ...state,
        coupanDetailData: action.payload,
      };

    default:
      return state;
  }
};

export default coupanReducer;
