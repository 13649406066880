import React from "react";
import {
  Card,
  Image,
  Typography,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
} from "antd";
import logo from "../../assets/img/comman/grandealzLogo.svg";
import { connect } from "react-redux";
import { actionChangePassword } from "../../store/actions/authAction";
import { useNavigate } from "react-router-dom";
const { Title } = Typography;

const ChangePassword = (props) => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const { actionChangePassword, changePasswordLoader } = props;
  const onFinish = (values) => {
    const reset = () => {
      form.resetFields();
    };
    actionChangePassword(values, reset);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Card
        title={<Title level={3}>Change Password</Title>}
        style={{
          width: 500,
          margin: "auto",
        }}
      >
        <Form
          onFinish={onFinish}
          form={form}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          <Row align="center" className="row-card">
            <Col span="24">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  // {
                  //   message:
                  //     "passwords must be longer than or equal to 8 characters !",
                  // },
                ]}
              >
                <Input.Password placeholder="Old password" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  // {
                  //   min: 8,
                  //   message:
                  //     "passwords must be longer than or equal to 8 characters !",
                  // },
                ]}
              >
                <Input.Password placeholder="New password" />
              </Form.Item>
            </Col>
            <Col span="24">
              <Form.Item
                name="confirm_new_password"
                dependencies={["new_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your  new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("new_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm New Password" />
              </Form.Item>
            </Col>
            <Col span={24} align="left" style={{ marginTop: "20px" }}>
              <Form.Item>
                <Button loading={changePasswordLoader} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    changePasswordLoader: state.auth.changePasswordLoader,
  };
};

export default connect(mapStateToProps, { actionChangePassword })(
  ChangePassword
);
