export const LOGIN_LOADING = "LOGIN_LOADING";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPLOAD_PIC_LOADING = "UPLOAD_PIC_LOADING";
export const UPLOAD_PIC_DATA = "UPLOAD_PIC_DATA";
export const FORGOT_PASSWORD_LOADER = "FORGOT_PASSWORD_LOADER";
export const CHANGE_PASSWORD_LOADER = "CHANGE_PASSWORD_LOADER";
export const RESET_PASSWORD_LOADER = "RESET_PASSWORD_LOADER";
export const GET_ALL_BANNER_LOADING = "GET_ALL_BANNER_LOADING";
export const GET_ALL_BANNER_DATA = "GET_ALL_BANNER_DATA";
export const GET_DRAWS_LOADING = "GET_DRAWS_LOADING";
export const GET_DRAWS_DATA = "GET_DRAWS_DATA";
export const TOTAL_DRAWS_COUNT = "TOTAL_DRAWS_COUNT";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const TOTAL_USERS_COUNT = "TOTAL_USERS_COUNT";
export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const ADD_BANNER_LOADING = "ADD_BANNER_LOADING";
export const ADD_BANNER_DATA = "ADD_BANNER_DATA";
export const UPLOAD_BANNER_PIC_LOADING = "UPLOAD_BANNER_PIC_LOADING";
export const UPLOAD_BANNER_PIC_DATA = "UPLOAD_BANNER_PIC_DATA";
export const DELETE_BANNER_LOADING = "DELETE_BANNER_LOADING";
export const DELETE_BANNER_ID = "DELETE_BANNER_ID";
export const UPDATE_BANNER_LOADING = "UPDATE_BANNER_LOADING";
export const GET_BANNER_DETAIL_LOADING = "GET_BANNER_DETAIL_LOADING";
export const GET_BANNER_DETAIL_DATA = "GET_BANNER_DETAIL_DATA";
export const ADD_DRAWS_LOADING = "ADD_DRAWS_LOADING";
export const ADD_DRAWS_DATA = "ADD_DRAWS_DATA";
export const UPLOAD_DRAWS_PIC_LOADING = "UPLOAD_DRAWS_PIC_LOADING";
export const UPLOAD_DRAWS_PIC_DATA = "UPLOAD_DRAWS_PIC_DATA";
export const DELETE_DRAW_LOADING = "DELETE_DRAW_LOADING";
export const DELETE_DRAW_ID = "DELETE_DRAW_ID";
export const GET_DRAW_DETAIL_LOADING = "GET_DRAW_DETAIL_LOADING";
export const GET_DRAW_DETAIL_DATA = "GET_DRAW_DETAIL_DATA";
export const UPDATE_DRAW_LOADING = "UPDATE_DRAW_LOADING";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_ID = "DELETE_USER_ID";
export const GET_USER_DETAIL_LOADING = "GET_USER_DETAIL_LOADING";
export const GET_USER_DETAIL_DATA = "GET_USER_DETAIL_DATA";
export const GET_ALL_COUPAN_LOADING = "GET_ALL_COUPAN_LOADING";
export const GET_ALL_COUPAN_DATA = "GET_ALL_COUPAN_DATA";
export const TOTAL_COUPAN_COUNT = "TOTAL_COUPAN_COUNT";
export const DELETE_COUPAN_LOADING = "DELETE_COUPAN_LOADING";
export const DELETE_COUPAN_ID = "DELETE_COUPAN_ID";
export const ADD_COUPAN_LOADING = "ADD_COUPAN_LOADING";
export const ADD_COUPAN_DATA = "ADD_COUPAN_DATA";
export const UPDATE_COUPAN_LOADING = "UPDATE_COUPAN_LOADING";
export const GET_COUPAN_DETAIL_LOADING = "GET_COUPAN_DETAIL_LOADING";
export const GET_COUPAN_DETAIL_DATA = "GET_COUPAN_DETAIL_DATA";
export const GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA";
export const GET_CAMPAIGN_LOADING = "GET_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_LOADING = "DELETE_CAMPAIGN_LOADING";
export const DELETE_CAMPAIGN_ID = "DELETE_CAMPAIGN_ID";
export const ADD_CAMPAIGN_LOADING = "ADD_CAMPAIGN_LOADING";
export const ADD_CAMPAIGN_DATA = "ADD_CAMPAIGN_DATA";
export const UPDATE_CAMPAIGN_LOADING = "UPDATE_CAMPAIGN_LOADING";
export const GET_CAMPAIGN_DETAIL_DATA = "GET_CAMPAIGN_DETAIL_DATA";
export const GET_CAMPAIGN_DETAIL_LOADING = "GET_CAMPAIGN_DETAIL_LOADING";
export const GET_CAMPAIGN_TICKET_LOADING = "GET_CAMPAIGN_TICKET_LOADING";
export const GET_CAMPAIGN_TICKET_DATA = "GET_CAMPAIGN_TICKET_DATA";
export const INCREASE_CAMPAIGN_TICKET_LOADING =
  "INCREASE_CAMPAIGN_TICKET_LOADING";
export const INCREASE_CAMPAIGN_TICKET_DATA = "INCREASE_CAMPAIGN_TICKET_DATA";
export const ALLOWED_REPETATIVE_USERS_LOADING =
  "ALLOWED_REPETATIVE_USERS_LOADING";
export const ALLOWED_REPETATIVE_USERS = "ALLOWED_REPETATIVE_USERS";
export const SEARCH_ANNOUNCE_WINNER_LOADING = "SEARCH_ANNOUNCE_WINNER_LOADING";
export const SEARCH_ANNOUNCE_WINNER_DATA = "SEARCH_ANNOUNCE_WINNER_DATA";
export const ANNOUNCE_WINNER_LOADING = "ANNOUNCE_WINNER_LOADING";
export const ANNOUNCE_WINNER_DATA = "ANNOUNCE_WINNER_DATA";
export const NOT_FOUND_CAMPAIGN_TICKET_DATA = "NOT_FOUND_CAMPAIGN_TICKET_DATA";
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const TOTAL_ORDERS_COUNT = "TOTAL_ORDERS_COUNT";
export const GET_ORDER_DETAIL_LOADING = "GET_ORDER_DETAIL_LOADING";
export const GET_ORDER_DETAIL_DATA = "GET_ORDER_DETAIL_DATA";
export const SEARCH_ANNOUNCE_DRAW_WINNER_LOADING =
  "SEARCH_ANNOUNCE_DRAW_WINNER_LOADING";
export const SEARCH_ANNOUNCE_DRAW_WINNER_DATA =
  "SEARCH_ANNOUNCE_DRAW_WINNER_DATA";
export const DRAWS_ANNOUNCE_WINNER_LOADING = "DRAWS_ANNOUNCE_WINNER_LOADING";
export const DRAWS_ANNOUNCE_WINNER_DATA = "DRAWS_ANNOUNCE_WINNER_DATA";
export const SEARCH_DRAWS_ANNOUNCE_WINNER_DATA =
  "SEARCH_DRAWS_ANNOUNCE_WINNER_DATA";
export const UPDATE_ORDER_STATUS_LOADING = "UPDATE_ORDER_STATUS_LOADING";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const SEND_NOTIFICATION_DATA = "SEND_NOTIFICATION_DATA";
export const SEND_NOTIFICATION_LOADER = "SEND_NOTIFICATION_LOADER";
export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
