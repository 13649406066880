import {
  Card,
  Col,
  Avatar,
  Image,
  List,
  Row,
  Typography,
  Button,
  Popconfirm,
  Spin,
} from "antd";
import React, { useEffect, useMemo } from "react";
import { CheckCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  actionDeleteUser,
  actionGetUserDetail,
} from "../../store/actions/userAction";
const { Title, Text } = Typography;
const UserDetail = (props) => {
  const Navigate = useNavigate();
  const {
    actionGetUserDetail,
    userDetailLoader,
    userDetailData,
    actionDeleteUser,
  } = props;
  const { userId } = useParams();

  useEffect(() => {
    actionGetUserDetail(userId);
  }, []);

  const data = [
    {
      title: "Mobile",
      description: (
        <>
          {userDetailData &&
            userDetailData.country_phone_code + " " + userDetailData.phone}
        </>
      ),
    },
    {
      title: "Gender",
      description: (
        <>
          {userDetailData && userDetailData.gender === "M"
            ? "Male"
            : userDetailData && userDetailData.gender === "F"
            ? "Female"
            : "-"}
        </>
      ),
    },
    {
      title: "Role",
      description: <>{userDetailData && userDetailData.roles}</>,
    },
    {
      title: "Date Of Birth ",
      description: (
        <>
          {userDetailData && (
            <Text strong type="success">
              <CalendarOutlined /> {userDetailData.date_of_birth}
            </Text>
          )}
        </>
      ),
    },
    {
      title: "Nationality",
      description: <>{userDetailData && userDetailData.nationality}</>,
    },
    {
      title: "Country of Residence",
      description: <>{userDetailData && userDetailData.country_of_residence}</>,
    },
  ];

  const confirm = (id) => {
    actionDeleteUser(id, Navigate);

    console.log("deleted");
  };

  return (
    <>
      <Spin spinning={userDetailLoader}>
        <div className="user-detail">
          <Row gutter={16}>
            <Col span={6}>
              <Card
                className="padding-card"
                title={
                  <>
                    <Typography.Title level={4}>
                      View Profile <span className="text-line"></span>
                    </Typography.Title>
                  </>
                }
                bordered={false}
              >
                <div className="center-elements">
                  <Image
                    style={{ borderRadius: 5 }}
                    preview={{ mask: "" }}
                    width={150}
                    src={userDetailData.profile_pic}
                  />
                  <Title level={4}>
                    {userDetailData.first_name + " " + userDetailData.last_name}
                  </Title>
                  <Title level={4}>{userDetailData.email}</Title>
                </div>
              </Card>
            </Col>
            <Col span={17}>
              <Card
                className="big-card"
                title={
                  <>
                    <Typography.Title level={4}>
                      More Info<span className="text-line"></span>
                    </Typography.Title>
                  </>
                }
                bordered={false}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#F1F5F9",
                      }}
                    >
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
                <br />
                {/* <Col span={10}> */}
                {/* <Button  htmlType="button" size="large" onClick={() => deleteUser(userDetailData._id)}> */}
                <Link to={`/update-user/${userDetailData._id}`}>
                  <Button htmlType="button" size="large" style={{marginRight:10}}>
                    Update
                  </Button>
                </Link>
                <Popconfirm
                  title="Are you sure to delete this user?"
                  onConfirm={() => confirm(userDetailData._id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button htmlType="button" size="large">
                    Delete
                  </Button>
                </Popconfirm>
                {/* </Col> */}
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetailLoader: state.users.userDetailLoader,
    userDetailData: state.users.userDetailData,
  };
};

export default connect(mapStateToProps, {
  actionGetUserDetail,
  actionDeleteUser,
})(UserDetail);
