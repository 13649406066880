import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
  Upload,
} from "antd";
import React, { Fragment, useState } from "react";
import ImgCrop from "antd-img-crop";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  actionAddBanner,
  actionUpdateBanner,
  actionUploadBannerPic,
} from "../../store/actions/bannerAction";
import { BASE_URL_UPLOAD } from "../config/web-config";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateBanner = (props) => {
  const { actionAddBanner, bannerData, addBannerData, addBannerLoading } =
    props;

  const [form] = Form.useForm();
  const Navigate = useNavigate();

  // const [bannerPic, setBannerPic] = useState(false);

  const [loading, setLoading] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const onFinish = (values) => {
    const request = {
      ...values,
      image: imageUrl
        ? imageUrl.split("/").reverse()[0]
        : values.image.file.response.file.filename,
      background_image: bannerImageUrl
        ? bannerImageUrl.split("/").reverse()[0]
        : values.background_image.file.response.file.filename,
    };

    actionAddBanner(request, Navigate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChangePriceImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setBannerImageUrl(info.file.response.file.path);

        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Title level={4}>
                  Create <span className="text-line">Banner</span>
                  {/* {title} */}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={true}
                  layout="vertical"
                  name="normal_login"
                  form={form}
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[48, 0]}>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        name="title"
                        label="Title"
                        rules={[
                          {
                            required: true,
                            message: "Please enter title!",
                          },
                          {
                            validator: (_, value) =>
                              value.length >= 5 && value.length <=50
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    // "Please enter minimum 5 characters  for Campaign Name"
                                    "Banner title should not be less then 5 and more than 50 characters!"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Title"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label="Short Description"
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter short description!",
                          },
                          {
                            validator: (_, value) => value.length <=600
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    // "Please enter minimum 5 characters  for Campaign Name"
                                    "Banner Description should not be more than 600 characters!"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Short Description"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Button Text"
                        colon={false}
                        name="btn_text"
                        rules={[
                          {
                            required: true,
                            message: "Please enter button text!",
                          },
                          {
                            validator: (_, value) => value.length <=15
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Button Text should not be more than 15 characters!"
                                  )
                                ),
                          },

                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Button Text"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Button Url"
                        colon={false}
                        name="btn_url"
                        rules={[
                          {
                            required: true,
                            message: "Please enter button url!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Button Url"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Image (1000px * 750px)"
                        name="image"
                        rules={[
                          {
                            required: imageUrl ? false : true,
                            message: "Please input image!",
                          },
                        ]}
                      >
                        <ImgCrop rotate aspect={4 / 3} fillColor="transparent">
                          <Upload
                            name="file"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                  "GrandealzBackendJwtToken"
                                ),
                            }}
                            onChange={handleChange}
                          >
                            {imageUrl ? (
                              <img
                                src={imageUrl}
                                alt="file"
                                style={{
                                  width: "100%",
                                }}
                              />
                            ) : (
                              <Button icon={<UploadOutlined />}>
                                Select File
                              </Button>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Background Image(1920px * 600px)"
                        name="background_image"
                        rules={[
                          {
                            required: bannerImageUrl ? false : true,
                            message: "Please input background image!",
                          },
                        ]}
                      >
                        <ImgCrop rotate aspect={16 / 5} fillColor="transparent">
                          <Upload
                            name="file"
                            maxCount={1}
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                  "GrandealzBackendJwtToken"
                                ),
                            }}
                            onChange={handleChangePriceImage}
                          >
                            {bannerImageUrl ? (
                              <img
                                src={bannerImageUrl}
                                alt="file"
                                style={{
                                  width: "100%",
                                }}
                              />
                            ) : (
                              <Button icon={<UploadOutlined />}>
                                Select File
                              </Button>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item>
                        <Button
                          loading={addBannerLoading}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addBannerLoading: state.banner.addBannerLoading,
    addBannerData: state.banner.addBannerData,
    bannerData: state.banner.bannerData,
    bannerUpdateLoader: state.banner.bannerUpdateLoader,
    uploadBannerPicData: state.banner.uploadBannerPicData,
  };
};

export default connect(mapStateToProps, {
  actionAddBanner,
  actionUploadBannerPic,
  actionUpdateBanner,
})(CreateBanner);
