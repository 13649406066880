import React from "react";
import {
  Card,
  Image,
  Typography,
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
} from "antd";
import logo from "../../assets/img/comman/grandealzLogo.svg";
import { connect } from "react-redux";
import { actionResetPassword } from "../../store/actions/authAction";
import { useNavigate, useLocation } from "react-router-dom";
const { Title } = Typography;

const ResetPassword = (props) => {
  const Navigate = useNavigate();
  const { actionResetPassword, resetPasswordLoader } = props;

  const token = useLocation();
  const tokenSubmit = token.search.split("=")[1];
  const [form] = Form.useForm();

  const onFinish = (values) => {
    actionResetPassword(
      { password: values.password, token: tokenSubmit ? tokenSubmit : "" },
      form
    );
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Card
            className="login-card"
            title={<Image src={logo} preview={false} />}
            style={{
              width: window.innerWidth < 768 ? "auto" : 400,
              margin: "auto",
              marginBottom: "100px",
            }}
          >
            <Title level={3} style={{ textAlign: "center" }}>
              Reset Password
            </Title>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="large"
            >
              <Row align="center" className="row-card">
                <Col span="24">
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      {
                        min: 8,
                        message:
                          "passwords must be longer than or equal to 8 characters !",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Password" />
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item
                    name="cpassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Confirm Your Password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>
                </Col>

                <Col span={24} align="center">
                  <Form.Item>
                    <Button loading={resetPasswordLoader} htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordLoader: state.auth.resetPasswordLoader,
  };
};

export default connect(mapStateToProps, { actionResetPassword })(ResetPassword);
