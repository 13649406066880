import { Card, Col, Row, Spin, Typography, Descriptions, Image } from "antd";
import { CheckCircleOutlined ,CalendarOutlined } from "@ant-design/icons";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionGetCoupanDetail } from "../../store/actions/coupanAction";
const { Text, Paragraph } = Typography;

const CoupanDetail = (props) => {
    const { actionGetCoupanDetail, coupanDetailLoader, coupanDetailData } = props;

    const { coupanId } = useParams();
    useEffect(() => {
        actionGetCoupanDetail(coupanId);
    }, []);

    console.log(coupanDetailData.discount)

    return (
        <Fragment>
            <Spin spinning={coupanDetailLoader}>
                <Row gutter={[0, 24]} className="fragment">
                    <Col span={24}>
                        <Card
                            style={{
                                width: "100%",
                                minHeight: "80vh",
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Typography.Title level={4}>
                                        View Coupon <span className="text-line"></span>
                                    </Typography.Title>
                                </Col>
                                <Descriptions
                                    className="detail-page"
                                    style={{ width: "100%" }}
                                    column={1}
                                    title=""
                                    layout="horizontal"
                                    bordered
                                >
                                    <Descriptions.Item label="Coupon Code">
                                        {coupanDetailData ? coupanDetailData.coupon : ""}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Description">
                                        {coupanDetailData ? coupanDetailData.description : ""}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Coupon Quantity	">
                                        {coupanDetailData ? coupanDetailData.quantity : ""}
                                    </Descriptions.Item>

                                    <Descriptions.Item label="User Quantity">
                                        {coupanDetailData ? coupanDetailData.user_qty : ""}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Discount">
                                        {/* {coupanDetailData ? coupanDetailData.discount + "" + coupanDetailData.discount_type === "Percentage" ? "%" : "₹" : "gh"} */}
                                        {coupanDetailData ? coupanDetailData.discount : ""}{coupanDetailData.discount_type === 'Percentage' ? "%" : "₹"}

                                    </Descriptions.Item>

                                    <Descriptions.Item label="Status">
                                        {coupanDetailData ? <Text strong type="success">
                                            <CheckCircleOutlined /> {coupanDetailData.status}
                                        </Text> : ""}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="	Coupon Start Date">
                                        {coupanDetailData ? <Text strong type="success">
                                            <CalendarOutlined /> {coupanDetailData.from}
                                        </Text> : ""}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Coupon Expire Date">
                                        {coupanDetailData ? <Text strong type="success">
                                            <CalendarOutlined /> {coupanDetailData.to}
                                        </Text> : ""}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Row>{" "}
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        coupanDetailLoader: state.coupan.coupanDetailLoader,
        coupanDetailData: state.coupan.coupanDetailData,
    };
};

export default connect(mapStateToProps, { actionGetCoupanDetail })(
    CoupanDetail
);
