import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetUsersAll =
  (skip, limit, search, role, nationality) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_USERS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/user?limit=${limit}&skip=${skip}${
          search ? "&search=" + search : ""
        }${role ? "&role=" + role : ""}${
          nationality ? "&nationality=" + nationality : ""
        }`
      );
      // let result = await axios.get(
      //   `${BASE_URL}/user?${limit ? "&limit=" + limit : ""}${
      //     skip ? "&skip=" + skip : ""
      //   }${search ? "&search=" + search : ""}${role ? "&role=" + role : ""}${
      //     nationality ? "&nationality=" + nationality : ""
      //   }`
      // );
      dispatch({ type: actionTypes.GET_USERS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_USERS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_USERS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_USERS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_USERS_LOADING, payload: false });
    }
  };

export const actionAddUser =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_USER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/user`, userData);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_USER_DATA,
          payload: result.data.data,
        });
        message.success("User Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_USER_LOADING, payload: false });
    }
  };

export const actionDeleteUser =
  (id, navigate) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DELETE_USER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.delete(`${BASE_URL}/user/${id}`);
      dispatch({
        type: actionTypes.DELETE_USER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({ type: actionTypes.DELETE_USER_ID, payload: id });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({
        type: actionTypes.DELETE_USER_LOADING,
        payload: false,
      });
    }
  };

export const actionUpdateUser =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/user/${id}`, {
        ...userData,
      });
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/users`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_USER_LOADING, payload: false });
    }
  };

export const actionGetUserDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/user/${id}`);
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_USER_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_USER_DETAIL_LOADING, payload: false });
  }
};
