import {
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Image,
  Col,
  Card,
  Typography,
  Button,
  Input,
  List,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  SearchOutlined,
  CalendarOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  actionGetCoupans,
  actionDeleteCoupan,
} from "../../store/actions/coupanAction";
import { connect } from "react-redux";
const { Text } = Typography;

const Coupan = (props) => {
  const {
    actionGetCoupans,
    coupansData,
    deleteCoupanLoading,
    coupansLoader,
    actionDeleteCoupan,
    coupansCount,
  } = props;
  const [list, setList] = useState();
  const [coupan, setCoupan] = useState("");
  const dataList = [];
  const FilterDataList = [];

  useEffect(() => {
    actionGetCoupans(0, 10);
    setList(dataList);
  }, []);

  useMemo(() => {
    setList(dataList);
  }, [coupansData]);

  const confirm = (id) => {
    actionDeleteCoupan(id);
  };

  coupansData &&
    coupansData.map((e, index) => {
      dataList.push({
        key: index,
        _id: e._id,

        status: (
          <Text strong type="success">
            <CheckCircleOutlined /> {e.status}
          </Text>
        ),
        from: (
          <Text strong type="success">
            <CalendarOutlined /> {e.from}
          </Text>
        ),
        to: (
          <Text strong type="danger">
            <CalendarOutlined /> {e.to}
          </Text>
        ),
        user_qty: <Text strong>{e.user_qty}</Text>,
        coupon: (
          <Text strong type="danger">
            {e.coupon}
          </Text>
        ),
        quantity: <Text strong>{e.quantity}</Text>,
        discount: <Text strong>{e.discount}</Text>,
        discount_type: <Text strong>{e.discount_type}</Text>,
        description: <Text strong>{e.description}</Text>,
      });
    });

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Coupon Start Date",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Coupon Expire Date",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "User Quantity",
      dataIndex: "user_qty",
      key: "user_qty",
    },
    {
      title: "Coupon Code",
      dataIndex: "coupon",
      key: "coupon",
    },
    {
      title: "Coupon Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Discount type",
      dataIndex: "discount_type",
      key: "discount_type",
    },
    {
      title: "Coupon Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Action",
      fixed: "right",
      align: "center",
      width: "80px",
      key: "action",
      render: (item) => (
        <Dropdown
          placement="bottom"
          menu={{
            items: [
              {
                label: (
                  <span>
                    <Link
                      to={`/coupan-detail/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <EyeOutlined
                        style={{
                          color: "#FF3E66",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      View
                    </Link>
                  </span>
                ),
              },
              {
                label: (
                  <span>
                    <Link
                      to={`/update-coupan/${item._id}`}
                      style={{ color: "black" }}
                    >
                      <EditOutlined
                        style={{
                          color: "#FF3E66",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Edit
                    </Link>
                  </span>
                ),
              },
              {
                label: (
                  <Popconfirm
                    okButtonProps={{
                      loading: deleteCoupanLoading,
                    }}
                    placement="topLeft"
                    title="Are you sure to delete this Coupon?"
                    onConfirm={() => confirm(item._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span style={{ color: "black" }}>
                      <DeleteOutlined
                        style={{
                          color: "#FF3E66",
                          fontSize: "18px",
                          marginRight: 10,
                        }}
                      />
                      Delete
                    </span>
                  </Popconfirm>
                ),
              },
            ],
          }}
        >
          <a>
            <EllipsisOutlined style={{ fontSize: "20px" }} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <>
      <Fragment>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                minHeight: "80vh",
              }}
            >
              <Row gutter={[16, 16]} className="table-row">
                <Col span={24}>
                  <Typography.Title level={4}>
                    Coupons <span className="text-line"></span>
                  </Typography.Title>
                </Col>
                <Col span={19}>
                  <Link to="/create-coupan">
                    <Button htmlType="submit" size="medium">
                      Create Coupon
                    </Button>
                  </Link>
                </Col>
                <Col span={5} align="end">
                  <Input
                    style={{ width: "100%" }}
                    autoFocus="none"
                    type={"text"}
                    // onChange={(e) => {
                    //   setCoupan(e.target.value);
                    // }}
                    onChange={e => {
                      const currValue = e.target.value;
                      setCoupan(currValue);
                      const filteredData = coupansData.filter(entry =>
                        String(entry.coupon).toLowerCase().includes(String(currValue).toLocaleLowerCase())
                      );
                      filteredData &&
                        filteredData.map((e, index) => {
                          FilterDataList.push({
                            key: e._id,
                            _id: e._id,

                            status: (
                              <Text strong type="success">
                                <CheckCircleOutlined /> {e.status}
                              </Text>
                            ),
                            from: (
                              <Text strong type="success">
                                <CalendarOutlined /> {e.from}
                              </Text>
                            ),
                            to: (
                              <Text strong type="danger">
                                <CalendarOutlined /> {e.to}
                              </Text>
                            ),
                            user_qty: <Text strong>{e.user_qty}</Text>,
                            coupon: (
                              <Text strong type="danger">
                                {e.coupon}
                              </Text>
                            ),
                            quantity: <Text strong>{e.quantity}</Text>,
                            discount: <Text strong>{e.discount}</Text>,
                            discount_type: <Text strong>{e.discount_type}</Text>,
                            description: <Text strong>{e.description}</Text>,
                          });
                        });
                      setList(FilterDataList);
                    }}
                    prefix={<SearchOutlined className="site-form-item-icon" />}
                    placeholder="Search Here"
                  />
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    pagination={{ total: coupansCount, hideOnSinglePage: true }}
                    columns={columns}
                    dataSource={list}
                    loading={coupansLoader}
                    onChange={(e) =>
                      actionGetCoupans(e.current - 1, e.pageSize)
                    }
                    scroll={{
                      x: 1500,
                      y: window.innerWidth < 1500 ? "40vh" : "60vh",
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    coupansLoader: state.coupan.coupansLoader,
    coupansData: state.coupan.coupansData,
    deleteCoupanLoading: state.coupan.deleteCoupanLoading,
    coupansCount: state.coupan.coupansCount,
  };
};

export default connect(mapStateToProps, {
  actionDeleteCoupan,
  actionGetCoupans,
})(Coupan);
