import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetBannerAll =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_ALL_BANNER_LOADING, payload: true });
    try {
      let result = await axios.get(`${BASE_URL}/banners`, userData);
      dispatch({ type: actionTypes.GET_ALL_BANNER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_ALL_BANNER_DATA,
          payload: result.data.data,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_ALL_BANNER_DATA,
          payload: [],
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({ type: actionTypes.GET_ALL_BANNER_LOADING, payload: false });
    }
  };

export const actionAddBanner =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_BANNER_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/banners`, userData);
      dispatch({ type: actionTypes.ADD_BANNER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_BANNER_DATA,
          payload: result.data.data,
        });
        message.success("Banner Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/banner`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_BANNER_LOADING, payload: false });
    }
  };

export const actionUploadBannerPic =
  (formData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPLOAD_BANNER_PIC_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/uploads`, formData, {
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({ type: actionTypes.UPLOAD_BANNER_PIC_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPLOAD_BANNER_PIC_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPLOAD_BANNER_PIC_LOADING, payload: false });
    }
  };

export const actionDeleteBanner = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_BANNER_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/banners/${id}`);
    dispatch({
      type: actionTypes.DELETE_BANNER_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_BANNER_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_BANNER_LOADING,
      payload: false,
    });
  }
};

export const actionUpdateBanner =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_BANNER_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/banners/${id}`, userData);
      dispatch({ type: actionTypes.UPDATE_BANNER_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/banner`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_BANNER_LOADING, payload: false });
    }
  };

export const actionGetBannerDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_BANNER_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/banners/${id}`);
    dispatch({ type: actionTypes.GET_BANNER_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_BANNER_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_BANNER_DETAIL_LOADING, payload: false });
  }
};


