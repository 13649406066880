import React, { Fragment, useEffect, useMemo } from "react";
import { Col, Row, Card, Divider, Steps, Typography, Image, Spin } from "antd";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { actionGetOrderDetail } from "../../store/actions/orderAction";

const OrderDetail = (props) => {
  const { actionGetOrderDetail, orderDetailLoader, orderDetailData } = props;

  const { orderId } = useParams();

  useEffect(() => {
    actionGetOrderDetail(orderId);
  }, []);
  useMemo(() => {}, [orderDetailData]);

  const items = [];
  var current = 0;

  if (
    orderDetailData.order_status === "Confirmed" ||
    orderDetailData.order_status === "Shiped" ||
    orderDetailData.order_status === "Out For Delivery" ||
    orderDetailData.order_status === "Delivered"
  ) {
    items.push({
      title: "Confirmed",
    });
    items.push({
      title: "Shipped",
    });
    items.push({
      title: "Out For Delivery",
    });
    items.push({
      title: "Delivered",
    });
  }
  if (orderDetailData.order_status === "Confirmed") {
    current = 0;
  } else if (orderDetailData.order_status === "Shiped") {
    current = 1;
  } else if (orderDetailData.order_status === "Out For Delivery") {
    current = 2;
  } else if (orderDetailData.order_status === "Delivered") {
    current = 3;
  } else {
    current = 0;
  }
  return (
    <Fragment>
      <Spin spinning={orderDetailLoader}>
        <Card
          style={{
            width: "100%",
            minHeight: "80vh",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Title level={4}>
                View Order <span className="text-line"></span>
              </Typography.Title>
            </Col>
          </Row>
          <div className="spacer" />
          <Card bordered={false} className="bg-main">
            <Col span={24}>Orders ID - {orderId.toUpperCase()}</Col>
            <Col span={24}>
              Order Date -{" "}
              {dayjs(orderDetailData.createdAt).format("DD MMMM, YYYY")}
            </Col>
          </Card>
          <div className="spacer" />
          <Row>
            <Col span={24}>
              <Card bordered={false} className="bg-main">
                {orderDetailData.draws &&
                  orderDetailData.draws.length &&
                  orderDetailData.draws.map((data, index) => {
                    return (
                      <div key={index}>
                        <Row justify="space-around" align="middle">
                          <Col span={3}>
                            <Image
                              preview={false}
                              width={150}
                              className="image-background"
                              src={data.draw.product_image}
                              alt="product-image"
                            />
                          </Col>
                          <Col span={5}>
                            <p className="order-title">
                              {data.draw.product_title}
                            </p>
                            <p className="order-description">
                              {data.draw.draw_title}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="order-price">
                              ₹ {data.draw.product_price}
                            </p>
                          </Col>
                          <Col span={3}>
                            <p className="order-price">Quantity {data.qty}</p>
                          </Col>
                          <Col span={4}>
                            <p className="order-sub-description">
                              {data.draw_tickets
                                ? `${data.draw_tickets.length} Ticket`
                                : "Avail Your Ticket"}
                            </p>
                          </Col>
                          <Col span={4}>
                            <p className="order-sub-description">
                              {data.draw.draw_date
                                ? `Draw Date ${dayjs(
                                    data.draw.draw_date
                                  ).format("DD MMMM, YYYY")}`
                                : data.draw.max_draw_date
                                ? `Draw Date ${dayjs(
                                    data.draw.max_draw_date
                                  ).format("DD MMMM, YYYY")}`
                                : "Draw Date Not Decided"}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </Card>
            </Col>
          </Row>

          <div className="spacer" />
          {!orderDetailData.is_donate && orderDetailData.address && (
            <>
              <Card bordered={false} className="bg-main">
                <Row gutter={[24, 16]} justify="space-between" align="middle">
                  <Col span={24} align="middle">
                    <Steps
                      progressDot={true}
                      current={
                        orderDetailData.order_status === "Confirmed" && 0
                      }
                      size="small"
                      labelPlacement="vertical"
                      items={items}
                    />
                  </Col>
                </Row>
              </Card>
              <div className="spacer" />
              <>
                <Card bordered={false} className="bg-main">
                  <p className="order-title">Shipping Details</p>
                  <p className="order-sub-description">
                    {orderDetailData.address.name}
                  </p>
                  <p className="order-sub-description">
                    {`${orderDetailData.address.address}, ${orderDetailData.address.city.name}  ${orderDetailData.address.state.name}  ${orderDetailData.address.country.name} ${orderDetailData.address.pincode}.`}
                  </p>
                  <p className="order-sub-description">
                    Mobile:{" "}
                    <span className="order-title">
                      {" "}
                      {orderDetailData.address.phone}
                    </span>
                  </p>
                </Card>
                <div className="spacer" />
              </>
            </>
          )}
          <Card bordered={false} className="bg-main">
            <Row gutter={[24, 16]} justify="space-between" align="middle">
              <Col span={24} className="order-title">
                Price Details
              </Col>
              <Col span={24}>
                <Divider className="order-divider" />
              </Col>
              <Col span={12} align="left">
                Total MRP
              </Col>
              <Col span={12} align="right">
                ₹ {orderDetailData.sub_total}
              </Col>
              <Col span={12} align="left">
                Tax (GST)
              </Col>
              <Col span={12} align="right">
                ₹ {orderDetailData.tax}
              </Col>
              {/* <Col span={12} align="left">
              Promo Code
            </Col>
            <Col span={12} align="right">
              ₹ 10.00
            </Col> */}
              <Col span={24}>
                <Divider className="order-divider" />
              </Col>
              <Col span={12} align="left">
                Total Amount
              </Col>
              <Col span={12} align="right">
                ₹ {orderDetailData.total}
              </Col>
            </Row>
          </Card>
          <div className="spacer" />
          <Card bordered={false} className="bg-main">
            <Row>
              <Col span={12}>
                <p>
                  {" "}
                  {orderDetailData.payment &&
                    orderDetailData.payment.method[0].toUpperCase() +
                      orderDetailData.payment.method.substring(1)}{" "}
                  ₹ {orderDetailData.total}
                </p>
              </Col>
            </Row>
          </Card>
        </Card>
      </Spin>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    orderDetailLoader: state.orders.orderDetailLoader,
    orderDetailData: state.orders.orderDetailData,
  };
};

export default connect(mapStateToProps, { actionGetOrderDetail })(OrderDetail);
