import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetCampaign = (userData) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_CAMPAIGN_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/campaign`);
    dispatch({ type: actionTypes.GET_CAMPAIGN_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_CAMPAIGN_DATA,
        payload: result.data.data,
      });
    } else if (parseInt(result.data.status) === 404) {
      dispatch({
        type: actionTypes.GET_CAMPAIGN_DATA,
        payload: [],
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({ type: actionTypes.GET_CAMPAIGN_LOADING, payload: false });
  }
};

export const actionDeleteCampaign = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_CAMPAIGN_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/campaign/${id}`);
    dispatch({
      type: actionTypes.DELETE_CAMPAIGN_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_CAMPAIGN_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_CAMPAIGN_LOADING,
      payload: false,
    });
  }
};

export const actionAddCampaign =
  (userData, navigate, allowed_reapeted_users) =>
  async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_CAMPAIGN_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/campaign`, {
        ...userData,
        allowed_reapeted_users: true,
      });
      dispatch({ type: actionTypes.ADD_CAMPAIGN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_CAMPAIGN_DATA,
          payload: result.data.data,
        });
        message.success("Campaign Added Successfully", 5);
        navigate && setTimeout(() => navigate(`/campaign`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_CAMPAIGN_LOADING, payload: false });
    }
  };

export const actionUpdateCampaign =
  (userData, navigate, id, winner) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_CAMPAIGN_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/campaign/${id}`, {
        ...userData,
        winner: "winner",
      });
      dispatch({ type: actionTypes.UPDATE_CAMPAIGN_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/campaign`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_CAMPAIGN_LOADING, payload: false });
    }
  };

export const actionGetCampaignDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/campaign/${id}`);
    dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_CAMPAIGN_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_CAMPAIGN_DETAIL_LOADING, payload: false });
  }
};
export const actionGetCampaignPrintTicket =
  (id, skip) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_CAMPAIGN_TICKET_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/campaign/print-tickets/${id}?limit=1440&skip=${parseInt(
          skip
        )}`
      );
      dispatch({
        type: actionTypes.GET_CAMPAIGN_TICKET_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_CAMPAIGN_TICKET_DATA,
          payload: result.data.data,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.NOT_FOUND_CAMPAIGN_TICKET_DATA,
          payload: "No More Data",
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.GET_CAMPAIGN_TICKET_LOADING,
        payload: false,
      });
    }
  };

export const actionCampaignIncreaseTicket =
  (id, userData, setModalPriceIncrese, setTicketNo, form) =>
  async (dispatch, getState) => {
    dispatch({
      type: actionTypes.INCREASE_CAMPAIGN_TICKET_LOADING,
      payload: true,
    });
    try {
      let result = await axios.put(
        `${BASE_URL}/campaign/increase-tickets/${id}`,
        userData
      );
      dispatch({
        type: actionTypes.INCREASE_CAMPAIGN_TICKET_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        dispatch({
          type: actionTypes.INCREASE_CAMPAIGN_TICKET_DATA,
          payload: { _id: id, no_of_tickets: userData },
        });
        setModalPriceIncrese && setModalPriceIncrese(false);
        setTicketNo && setTicketNo("");
        form && form.resetFields();
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.INCREASE_CAMPAIGN_TICKET_LOADING,
        payload: false,
      });
    }
  };

export const actionCampaignAllowRepetativeUsers =
  (id, userData) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ALLOWED_REPETATIVE_USERS_LOADING,
      payload: true,
    });
    try {
      let result = await axios.put(
        `${BASE_URL}/campaign/repititive-customer-toggle/${id}`,
        userData
      );
      dispatch({
        type: actionTypes.ALLOWED_REPETATIVE_USERS_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ALLOWED_REPETATIVE_USERS,
          payload: {
            _id: id,
            userData,
          },
        });
        message.success(result.data.message, 5);
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.ALLOWED_REPETATIVE_USERS_LOADING,
        payload: false,
      });
    }
  };

export const actionGetAnnounceUserDetail =
  (id, ticketNo, form) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.SEARCH_ANNOUNCE_WINNER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/campaign/find-winner/${id}?ticket_number=${ticketNo}`
      );
      dispatch({
        type: actionTypes.SEARCH_ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.SEARCH_ANNOUNCE_WINNER_DATA,
          payload: result.data.data,
        });
        form && form.resetFields();
      } else {
        message.error(result.data.message, 5);
        dispatch({
          type: actionTypes.SEARCH_ANNOUNCE_WINNER_DATA,
          payload: [],
        });
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SEARCH_ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
    }
  };

export const actionCampaignAnnounceWinner =
  (id, userData, setModalAnnouceWinner) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.ANNOUNCE_WINNER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.put(
        `${BASE_URL}/campaign/update-winner/${id}`,
        userData
      );
      dispatch({
        type: actionTypes.ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ANNOUNCE_WINNER_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        setModalAnnouceWinner && setModalAnnouceWinner();
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
    }
  };

export const actionRemoveWinnerOnModalClose = () => async (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_ANNOUNCE_WINNER_DATA,
    payload: {},
  });
};
