import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Upload,
  Image,
  Spin,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  actionGetBannerDetail,
  actionUpdateBanner,
  actionUploadBannerPic,
} from "../../store/actions/bannerAction";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { BASE_URL_UPLOAD } from "../config/web-config";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};


const UpdateBanner = (props) => {
  const {
    bannerUpdateLoader,
    actionUpdateBanner,
    bannerDetailData,
    bannerDetailLoader,
    actionGetBannerDetail,
  } = props;

  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { bannerId } = useParams();

  const [loading, setLoading] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState();
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    actionGetBannerDetail(bannerId);
  }, []);
  useMemo(() => {}, [bannerDetailData]);

  useEffect(() => {
    form.setFieldsValue({
      title: bannerDetailData.title,
      description: bannerDetailData.description,
      btn_text: bannerDetailData.btn_text,
      btn_url: bannerDetailData.btn_url,
      image: bannerDetailData.image,
      background_image: bannerDetailData.background_image,
    });
    setBannerImageUrl(bannerDetailData.background_image);
    setImageUrl(bannerDetailData.image);
  }, [bannerDetailData]);

  const onFinish = (values) => {
    const request = {
      ...values,
      image: imageUrl
        ? imageUrl.split("/").reverse()[0]
        : values.image.file.response.file.filename,
      background_image: bannerImageUrl
        ? bannerImageUrl.split("/").reverse()[0]
        : values.background_image.file.response.file.filename,
    };

    actionUpdateBanner(request, Navigate, bannerId);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChangePriceImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setBannerImageUrl(info.file.response.file.path);

        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Fragment>
      <Spin spinning={bannerDetailLoader}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Card
              style={{
                width: "100%",
                minHeight: "60vh",
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Title level={4}>
                    Update <span className="text-line">Banner</span>
                  </Typography.Title>
                </Col>
                <Col span={24}>
                  <Form
                    autoComplete="off"
                    requiredMark={true}
                    layout="vertical"
                    name="normal_login"
                    form={form}
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[48, 0]}>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Please enter title!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Title"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="description"
                          label="Short Description"
                          rules={[
                            {
                              required: true,
                              message: "Please enter short description!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Short Description"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          name="btn_text"
                          label="Button Text"
                          rules={[
                            {
                              required: true,
                              message: "Please enter button text!",
                            },
                             {
                            validator: (_, value) => value.length <=15
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Button Text should not be more than 15 characters!"
                                  )
                                ),
                          }, 
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Button Text"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          colon={false}
                          label="Button Url"
                          name="btn_url"
                          rules={[
                            {
                              required: true,
                              message: "Please enter button url!",
                            },
                          ]}
                        >
                          <Input
                            autoFocus="none"
                            type={"text"}
                            placeholder="Button Url"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="image"
                          label="Image (1000px * 750px)"
                          rules={[
                            {
                              required: true,
                              message: "Please input image!",
                            },
                          ]}
                        >
                          <ImgCrop
                            rotate
                            aspect={4 / 3}
                            fillColor="transparent"
                          >
                            <Upload
                              name="file"
                              // listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem(
                                    "GrandealzBackendJwtToken"
                                  ),
                              }}
                              onChange={handleChange}
                            >
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  alt="file"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                <Button icon={<UploadOutlined />}>
                                  Select File
                                </Button>
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                        label="Background Image(1920px * 600px)"
                          name="background_image"
                          rules={[
                            {
                              required: true,
                              message: "Please input image!",
                            },
                          ]}
                        >
                          <ImgCrop
                            rotate
                            aspect={16 / 5}
                            fillColor="transparent"
                          >
                            <Upload
                              name="file"
                              maxCount={1}
                              showUploadList={false}
                              action={BASE_URL_UPLOAD}
                              headers={{
                                Authorization:
                                  "Bearer " +
                                  localStorage.getItem(
                                    "GrandealzBackendJwtToken"
                                  ),
                              }}
                              onChange={handleChangePriceImage}
                            >
                              {bannerImageUrl ? (
                                <img
                                  src={bannerImageUrl}
                                  alt="file"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                <Button icon={<UploadOutlined />}>
                                  Select File
                                </Button>
                              )}
                            </Upload>
                          </ImgCrop>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item>
                          <Button
                            loading={bannerUpdateLoader}
                            align="center"
                            htmlType="submit"
                            className="primary"
                            size="large"
                            style={{ width: 200, marginTop: "30px" }}
                          >
                            Update
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerUpdateLoader: state.banner.bannerUpdateLoader,
    uploadBannerPicData: state.banner.uploadBannerPicData,
    bannerDetailLoader: state.banner.bannerDetailLoader,
    bannerDetailData: state.banner.bannerDetailData,
  };
};

export default connect(mapStateToProps, {
  actionUploadBannerPic,
  actionUpdateBanner,
  actionGetBannerDetail,
})(UpdateBanner);
