import React, { Fragment, useState, useEffect, useMemo } from "react";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  message,
  Upload,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { actionUpdateUserProfile } from "../../store/actions/authAction";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { BASE_URL_UPLOAD } from "../config/web-config";
import moment  from "moment";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EditProfile = (props) => {
  const { updateProfileLoader, actionUpdateUserProfile, userProfileData } = props;

  const [loading, setLoading] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState();

  const Navigate = useNavigate();

  const [form] = Form.useForm();

 
  useMemo(() => {}, [userProfileData]);

  useEffect(() => {
    userProfileData &&
      form.setFieldsValue({
        first_name: userProfileData.first_name,
        last_name: userProfileData.last_name,
        email: userProfileData.email,
        gender: userProfileData.gender,
        profile_pic: userProfileData.profile_pic,
        country_phone_code: userProfileData.country_phone_code,
        phone: userProfileData.phone,
        country_of_residence: userProfileData.country_of_residence,
        nationality: userProfileData.nationality,
        date_of_birth: userProfileData.date_of_birth
          ? moment(userProfileData.date_of_birth)
          : undefined,
      });
      setProfilePicUrl(userProfileData.profile_pic)
  }, [userProfileData]);

  const onFinish = (values) => {
    const data = {
      ...values,
      profile_pic: profilePicUrl
      ? profilePicUrl.split("/").reverse()[0]
      : values.profile_pic.file.response.file.filename,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
    };
    actionUpdateUserProfile(data, userProfileData._id);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  const handleChangeUserPic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setProfilePicUrl(info.file.response.file.path);
        //actionUploadBannerPic(url, Navigate);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  
  return (
    <Fragment>
      <Spin spinning={updateProfileLoader}>

      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Typography.Title level={4}>
                  <span className="text-line">Personal Details</span>
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Form
                  autoComplete="off"
                  requiredMark={"optional"}
                  form={form}
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[32, 0]}>
                    <Col span={12}>
                      <span>First Name</span>
                      <Form.Item
                        colon={false}
                        name="first_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input your first name!",
                          },
                          {
                            validator: (_, value) =>
                              value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for first Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="first name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span>Last Name</span>
                      <Form.Item
                        colon={false}
                        name="last_name"
                        normalize={(value) =>
                          value.replace(/[^a-zA-Z]/g, "").trim()
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name!",
                          },
                          {
                            validator: (_, value) =>
                              value.length <= 15
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "Please enter maximum 15 characters for last Name"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="last name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span>Email</span>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            // message: "Please enter email!",
                            message: "The input is not valid E-mail!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Email"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <span>Mobile</span>

                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="country_phone_code"
                        rules={[
                          {
                            required: true,
                            message: "Please select country code.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="+91"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "1",
                              label: "+91",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <span style={{ color: "transparent" }}>transparent </span>

                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please enter mobile!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && value.length == 10) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Invalid Mobile Number!")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          maxLength={2}
                          autoFocus="none"
                          type={"number"}
                          placeholder="Mobile"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <span>Date Of Birth</span>
                      <Form.Item
                        // normalize={(value) => value.trim()}
                        colon={false}
                        name="date_of_birth"
                        rules={[
                          {
                            required: true,
                            message: "Please enter date of birth!",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span>Gender </span>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "Please select gender.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select gender"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "M",
                              label: "Male",
                            },
                            {
                              value: "F",
                              label: "Female",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span>Nationality</span>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="nationality"
                        rules={[
                          {
                            required: true,
                            message: "Please select nationality.!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select nationality"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? "").includes(input)
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                              .toLowerCase()
                              .localeCompare(
                                (optionB?.label ?? "").toLowerCase()
                              )
                          }
                          options={[
                            {
                              value: "Indian",
                              label: "Indian",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <span>Country of Residence</span>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        colon={false}
                        name="country_of_residence"
                        rules={[
                          {
                            required: true,
                            message: "Please select country of residence.!",
                          },
                        ]}
                      >
                        <Input placeholder="country of residence" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <p>Image (200px * 200px)</p>
                      <Form.Item
                        normalize={(value) => value.trim()}
                        name="profile_pic"
                        rules={[
                          {
                            required: profilePicUrl ? false : true,
                            message: "Please input image!",
                          },
                        ]}
                      >
                        <ImgCrop rotate aspect={1 / 1} fillColor="white">
                          <Upload
                            name="file"
                            maxCount={1}
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                  "GrandealzBackendJwtToken"
                                ),
                            }}
                            onChange={handleChangeUserPic}
                          >
                            {profilePicUrl ? (
                              <img
                                src={profilePicUrl}
                                alt="file"
                                style={{
                                  width:200,
                                }}
                              />
                            ) : (
                              <Button icon={<UploadOutlined />}>
                                Select File
                              </Button>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item normalize={(value) => value.trim()}>
                        <Button
                          loading={updateProfileLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      </Spin>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfileData: state.auth.userProfileData,
    updateProfileLoader: state.auth.updateProfileLoader,
  };
};

export default connect(mapStateToProps, { actionUpdateUserProfile })(EditProfile);
