import * as actionTypes from "../action";

const initialState = {
  bannerLoader: false,
  bannerData: [],
  addBannerLoading: false,
  addBannerData: [],
  uploadBannerPicLoader: false,
  uploadBannerPicData: {},
  deleteBannerLoading: false,
  bannerUpdateLoader: false,
  bannerDetailLoader: false,
  bannerDetailData: {},
  allBannerDataLoader: false,
  allBannersData: [],
  bannerNotFound: false,
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_BANNER_LOADING:
      return { ...state, bannerLoader: action.payload };

    case actionTypes.GET_ALL_BANNER_DATA:
      return { ...state, bannerData: action.payload };

    case actionTypes.ADD_BANNER_LOADING:
      return { ...state, addBannerLoading: action.payload };

    case actionTypes.ADD_BANNER_DATA:
      return { ...state, addBannerData: action.payload };

    case actionTypes.UPLOAD_BANNER_PIC_LOADING:
      return { ...state, uploadBannerPicLoader: action.payload };

    case actionTypes.UPLOAD_BANNER_PIC_DATA:
      return { ...state, uploadBannerPicData: action.payload };

    case actionTypes.DELETE_BANNER_LOADING:
      return { ...state, deleteBannerLoading: action.payload };

    case actionTypes.DELETE_BANNER_ID:
      return {
        ...state,
        bannerData: [
          ...state.bannerData.filter((data) => data._id !== action.payload),
        ],
      };

    case actionTypes.UPDATE_BANNER_LOADING:
      return { ...state, bannerUpdateLoader: action.payload };

    case actionTypes.GET_BANNER_DETAIL_LOADING:
      return { ...state, bannerDetailLoader: action.payload };

    case actionTypes.GET_BANNER_DETAIL_DATA:
      return {
        ...state,
        bannerDetailData: action.payload,
      };

    default:
      return state;
  }
};

export default bannerReducer;
