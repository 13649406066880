import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
  Upload,
} from "antd";
import dayjs from "dayjs";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionAddCampaign } from "../../store/actions/campaignAction";
import ImgCrop from "antd-img-crop";
import { BASE_URL_UPLOAD } from "../config/web-config";
const { TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateCampaign = (props) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [prizeImageUrl, setPrizeImageUrl] = useState();
  const [campaignImageUrl, setCampaignImageUrl] = useState();
  const { actionAddCampaign, addCampaignLoader, addCampaignData } = props;

  const handleChangePriceImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setPrizeImageUrl(info.file.response.file.path);
        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleChangeCampaignImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setCampaignImageUrl(info.file.response.file.path);

        message.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinish = (values) => {
    const request = {
      ...values,
      campaign_image: campaignImageUrl
        ? campaignImageUrl.split("/").reverse()[0]
        : values.campaign_image.file.response.file.filename,
      prize_image: prizeImageUrl
        ? prizeImageUrl.split("/").reverse()[0]
        : values.prize_image.file.response.file.filename,
      date_of_draw: dayjs(values.date_of_draw).format("YYYY-MM-DD"),
      no_of_tickets: parseInt(values.no_of_tickets),
    };

    actionAddCampaign(request, Navigate);
    console.log(request, "request");
  };

  const onFinishFailed = (errorInfo) => {
    console.log(
      "Failed:",
      dayjs(errorInfo.values.draw_date).format("YYYY-MM-DD")
    );
  };
  return (
    <Fragment>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Card
            style={{
              width: "100%",
              minHeight: "60vh",
            }}
          >
            <Row>
              <Col span={24}>
                <Typography.Title level={4}>
                  Create <span className="text-line">Campaign </span>
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Form
                  initialValues={{
                    status: "Active",
                  }}
                  autoComplete="off"
                  layout='vertical'
                  requiredMark={true}
                  name="normal_login"
                  form={form}
                  size="large"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row gutter={[48, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label="Campaign Name"
                        colon={false}
                        normalize={(value) =>
                          value
                            .replace(/[^a-z0-9A-Z]/g, "")
                            .toUpperCase()
                        }
                        name="campaign_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Campaign Name!",
                          },
                          {
                            validator: (_, value) =>
                              value.length >= 5
                                ? Promise.resolve()
                                : Promise.reject(
                                  new Error(
                                    "Please enter minimum 5 characters for Campaign Name"
                                  )
                                ),
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Campaign Name*"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Campaign Location"
                        colon={false}
                        name="campaign_location"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Campaign Location!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"text"}
                          placeholder="Campaign Location"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        colon={false}
                        label='Date of Draw'
                        name="date_of_draw"
                        rules={[
                          {
                            required: true,
                            message: "Please Select date of draw!",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Number of Tickets"
                        colon={false}
                        name="no_of_tickets"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Number Of Tickets!",
                          },
                        ]}
                      >
                        <Input
                          autoFocus="none"
                          type={"number"}
                          placeholder="Number Of Tickets"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Status"
                        colon={false}
                        name="status"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Status!",
                          },
                        ]}
                      >
                        <Select placeholder="Select a Status" allowClear>
                          <Option value="Active">Active</Option>
                          <Option value="Completed">Completed</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12} />
                    <Col span={12}>
                      <Form.Item
                        label='About Prize'
                        colon={false}
                        name="about_prize"
                        rules={[
                          {
                            required: true,
                            message: "Please enter About Prize!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          autoFocus="none"
                          type={"text"}
                          placeholder="About Prize"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label='Description'
                        colon={false}
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter description!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          autoFocus="none"
                          type={"text"}
                          placeholder="Description"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        label="Prize image (100px * 100px)"
                        name="prize_image"
                        rules={[
                          {
                            required: prizeImageUrl ? false : true,
                            message: "Please input price image!",
                          },
                        ]}
                      >
                        <ImgCrop rotate aspect={4 / 3} fillColor="white">
                          <Upload
                            name="file"
                            maxCount={1}
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                  "GrandealzBackendJwtToken"
                                ),
                            }}
                            //beforeUpload={beforeUploadpriceImage}
                            onChange={handleChangePriceImage}
                          >
                            {prizeImageUrl ? (
                              <img
                                src={prizeImageUrl}
                                alt="file"
                                style={{
                                  width: 100,
                                }}
                              />
                            ) : (
                              <Button icon={<UploadOutlined />}>
                                Select File
                              </Button>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label='Campaign image* (200px * 200px)'
                        name="campaign_image"
                        rules={[
                          {
                            required: campaignImageUrl ? false : true,
                            message: "Please input campaign_image image!",
                          },
                        ]}
                      >
                        <ImgCrop rotate aspect={4 / 3} fillColor="white">
                          <Upload
                            name="file"
                            maxCount={1}
                            showUploadList={false}
                            action={BASE_URL_UPLOAD}
                            headers={{
                              Authorization:
                                "Bearer " +
                                localStorage.getItem(
                                  "GrandealzBackendJwtToken"
                                ),
                            }}
                            //beforeUpload={beforeUploadProductImage}
                            onChange={handleChangeCampaignImage}
                          >
                            {campaignImageUrl ? (
                              <img
                                src={campaignImageUrl}
                                alt="file"
                                style={{
                                  width: 100,
                                }}
                              />
                            ) : (
                              <Button icon={<UploadOutlined />}>
                                Select File
                              </Button>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item>
                        <Button
                          loading={addCampaignLoader}
                          align="center"
                          htmlType="submit"
                          className="primary"
                          size="large"
                          style={{ width: 200, marginTop: "30px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    addCampaignLoader: state.campaign.addCampaignLoader,
    addCampaignData: state.campaign.addCampaignData,
  };
};

export default connect(mapStateToProps, {
  actionAddCampaign,
})(CreateCampaign);
