import axios from "axios";
import { BASE_URL } from "../../components/config/web-config";
import * as actionTypes from "../action";
import { message } from "antd";

export const actionGetDrawsAll =
  (skip, limit, search, status) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.GET_DRAWS_LOADING, payload: true });
    try {
      let result = await axios.get(
        `${BASE_URL}/draws?limit=${limit}&skip=${skip}${
          search ? "&search=" + search : ""
        }${status ? "&status=" + status : ""}`
      );
      dispatch({ type: actionTypes.GET_DRAWS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.GET_DRAWS_DATA,
          payload: result.data.data,
        });
        dispatch({
          type: actionTypes.TOTAL_DRAWS_COUNT,
          payload: result.data.total_records,
        });
      } else if (parseInt(result.data.status) === 404) {
        dispatch({
          type: actionTypes.GET_DRAWS_DATA,
          payload: [],
        });
        dispatch({
          type: actionTypes.TOTAL_DRAWS_COUNT,
          payload: 0,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.GET_DRAWS_LOADING, payload: false });
    }
  };

export const actionAddDraws =
  (userData, navigate) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.ADD_DRAWS_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/draws`, {
        ...userData,
        currency: "INR",
      });
      dispatch({ type: actionTypes.ADD_DRAWS_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.ADD_DRAWS_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/draws`), 1000);
      } else {
        message.error(result.data.message, 5);
      }
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.ADD_DRAWS_LOADING, payload: false });
    }
  };

export const actionUploadDrawsPic =
  (formData) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPLOAD_DRAWS_PIC_LOADING, payload: true });
    try {
      let result = await axios.post(`${BASE_URL}/uploads`, formData, {
        mode: "no-cors",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({ type: actionTypes.UPLOAD_DRAWS_PIC_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.UPLOAD_DRAWS_PIC_DATA,
          payload: result.data.data,
        });
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPLOAD_DRAWS_PIC_LOADING, payload: false });
    }
  };

export const actionDeleteDraw = (id) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.DELETE_DRAW_LOADING,
    payload: true,
  });
  try {
    let result = await axios.delete(`${BASE_URL}/draws/${id}`);
    dispatch({
      type: actionTypes.DELETE_DRAW_LOADING,
      payload: false,
    });
    if (parseInt(result.data.status) === 200) {
      dispatch({ type: actionTypes.DELETE_DRAW_ID, payload: id });
      message.success(result.data.message, 5);
    } else message.error(result.data.message, 5);
  } catch (error) {
    message.error(error.message, 5);
    dispatch({
      type: actionTypes.DELETE_DRAW_LOADING,
      payload: false,
    });
  }
};

export const actionUpdateDraw =
  (userData, navigate, id) => async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_DRAW_LOADING, payload: true });
    try {
      let result = await axios.put(`${BASE_URL}/draws/${id}`, {
        ...userData,
        currency: "INR",
      });
      dispatch({ type: actionTypes.UPDATE_DRAW_LOADING, payload: false });
      if (parseInt(result.data.status) === 200) {
        message.success(result.data.message, 5);
        navigate && setTimeout(() => navigate(`/draws`), 1000);
      } else message.error(result.data.message, 5);
    } catch (error) {
      message.error(error.message, 5);
      dispatch({ type: actionTypes.UPDATE_DRAW_LOADING, payload: false });
    }
  };

export const actionGetDrawDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: true });
  try {
    let result = await axios.get(`${BASE_URL}/draws/${id}`);
    dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: false });
    if (parseInt(result.data.status) === 200) {
      dispatch({
        type: actionTypes.GET_DRAW_DETAIL_DATA,
        payload: result.data.data,
      });
    } else message.error(result.data.message, 5);
  } catch (error) {
    dispatch({ type: actionTypes.GET_DRAW_DETAIL_LOADING, payload: false });
  }
};

// announceWinnerDrawsId

export const actionGetAnnounceDrawsDetail =
  (id, ticketNo, form) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.get(
        `${BASE_URL}/draws/find-user/${id}?ticket_number=${ticketNo}`
      );
      dispatch({
        type: actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_DATA,
          payload: result.data.data,
        });
        form && form.resetFields();
      } else {
        // message.error('');
        dispatch({
          type: actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_DATA,
          payload: [],
        });
        form && form.resetFields();
      }
    } catch (error) {
      dispatch({
        type: actionTypes.SEARCH_ANNOUNCE_DRAW_WINNER_LOADING,
        payload: false,
      });
    }
  };

export const actionDrawsAnnounceWinner =
  (id, userData, setModalAnnouceWinner) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.DRAWS_ANNOUNCE_WINNER_LOADING,
      payload: true,
    });
    try {
      let result = await axios.put(
        `${BASE_URL}/draws/update-winner/${id}`,
        userData
      );
      dispatch({
        type: actionTypes.DRAWS_ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
      if (parseInt(result.data.status) === 200) {
        dispatch({
          type: actionTypes.DRAWS_ANNOUNCE_WINNER_DATA,
          payload: result.data.data,
        });
        message.success(result.data.message, 5);
        setModalAnnouceWinner && setModalAnnouceWinner();
      } else message.error(result.data.message, 5);
    } catch (error) {
      dispatch({
        type: actionTypes.DRAWS_ANNOUNCE_WINNER_LOADING,
        payload: false,
      });
    }
  };

export const actionRemoveWinnerOnModalClose = () => async (dispatch) => {
  dispatch({
    type: actionTypes.SEARCH_DRAWS_ANNOUNCE_WINNER_DATA,
    payload: {},
  });
};
